import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

import PageWrapper from '../components/PageWrapper';
import Odom from '../components/Odom';
// import Chart from '../components/Chart';
import rjmHeadshot from '../assets/images/MittelstaedtRHeadshot.png';
// import boardMembersIndependent from '../assets/images/boardMembersIndependent.png';
// import offsetGreaterThan from '../assets/images/offsetGreaterThan.png';
// import minimumWageTarget from '../assets/images/minimumWageTarget.png';
import newsIcon from '../assets/images/newsIcon.png';
import wasteConnectionsGroupNews from '../assets/images/wasteConnectionsGroupNews.png';
import lionElectricNews from '../assets/images/lionElectricNews.png';
import ampRoboticsNews from '../assets/images/ampRoboticsNews.png';
import columns from '../assets/images/columns2.png';
import people from '../assets/images/people.png';
import earth from '../assets/images/earth.png';
import greenEarth from '../assets/images/greenEarth.png';
import incidentRate from '../assets/images/incidentRate.png';
import voluntaryTurnover from '../assets/images/voluntaryTurnover.png';
import servantLeadership from '../assets/images/servantLeadership.png';
import esPdf from '../assets/WasteConnections_2023_Sustainability Report.pdf';
import blueDown from '../assets/images/blueDown.png';
import blueUp from '../assets/images/blueUp.png';
import bioGas from '../assets/images/gasCollected.png';
import leachate from '../assets/images/leachate.png';
import recycling from '../assets/images/recycling.png';
import greenUp from '../assets/images/greenUp.png';
import safetyCulture from '../assets/images/safetyCulture.jpg';
import cardboardRecycling from '../assets/images/CardboardRecycling.jpg';

const style = {
  general: {
    height: 48,
    marginBottom: 8,
  },
  odomStyle: {
    fontSize: 44,
    fontWeight: 'bold',
    color: 'white',
    paddingRight: 4,
    paddingLeft: 4
  },
  textStyle: {
    fontSize: 44,
    fontWeight: 'bold',
    color: 'white',
    paddingTop: 24
  },
  preTextStyle: {
    fontSize: 44,
    fontWeight: 'bold',
    color: 'white',
    paddingTop: 24
  }
}

const targetProcessStyle = {
  general: {
    height: 48,
    marginBottom: 8,
    width: 164,
    justifyContent: 'flex-start'
  },
  odomStyle: {
    fontSize: 72,
    fontWeight: 'bold',
    color: '#68B23E',
    paddingRight: 4,
    paddingLeft: 4,
  },
  textStyle: {
    fontSize: 72,
    fontWeight: 'bold',
    color: '#68B23E',
    paddingTop: 18
  },
  preTextStyle: {
    fontSize: 80,
    fontWeight: 'bold',
    color: '#68B23E',
    paddingTop: 16
  },
  blue: {
    color: '#0161A5',
  }
}

const HomePage = (props) => {
  const { width, isMobile } = props;

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <div className='hero'>
        <br />
        <br />
        <h1 style={{ color: 'white', fontWeight: 600 }}>
          Sustainability efforts are integral to our business, driven
          by an intentional focus and investments that are consistent
          with our objective of long-term value creation and part of
          who we are as Waste Connections.
        </h1>
      </div>
      <Container className='messageFromCeo' fluid>
        <Row className='content'>
          <Col xs={12} md={6} lg={4} className='portrait'>
           <img src={rjmHeadshot} aria-label="Headshot of President and CEO Ronald J. Mittelstaedt" />
            <br />
            <br />
            <div className='description'>
              <strong>
               Ronald J. Mittelstaedt
              </strong>
              <br />
              President and Chief Executive Officer
            </div>
          </Col>
          <Col xs={12} md={6} lg={8} className='quote'>
            <div>
              “Our investments in resource recovery through recycling and renewable natural gas (RNG) facilities, leachate processing systems and PFAS treatment not only drive financial returns but also benefit the environment and the communities we have the privilege to serve. Moreover, our servant leadership-based, safety-oriented culture empowers our frontline employees through a decentralized operating structure and celebrates the benefits of an inclusive, supportive environment.”
            </div>
            <br />
            <Link smooth to="/ceo-letter#top">
              Read the full letter »
            </Link>
          </Col>
        </Row>
      </Container>

      <Container className='highlights' fluid>
        <Row className='content'>
          <Col>
            <h2>
              ESG HIGHLIGHTS
            </h2>
            <Row>
              <Col xs={12} lg={5}>
                <div className='accent'>
                  <img src={earth} alt="" className='highlight' />
                </div>
                <Row>
                  <Col xs={12} md={6}>
                    <Odom
                      number={4.2}
                      preText=""
                      text="x"
                      format="(.ddd).dd"
                      style={style.general}
                      odomStyle={style.odomStyle}
                      textStyle={style.textStyle}
                      preTextStyle={style.preTextStyle}
                    />
                    {/* <div style={{ fontName: 'Lato', fontSize: '1rem', fontWeight: 'bold', color: 'white' }}>
                      BEYOND NET
                    </div>
                    <div style={{ fontName: 'Lato', fontSize: 44, fontWeight: 'bold', color: 'white', lineHeight: 1, marginBottom: 6 }}>
                      ZERO
                    </div> */}
                    <div className="description">
                      Our offsets from operations exceed our emissions
                    </div>
                    <hr />
                    <Odom
                      number={14}
                      preText=""
                      text="%"
                      format="(.ddd).dd"
                      style={style.general}
                      odomStyle={style.odomStyle}
                      textStyle={style.textStyle}
                      preTextStyle={style.preTextStyle}
                    />
                    <div className="description">
                      Reduction in Scope 1 & 2 emissions intensity in 2022
                    </div>
                    <br />

                    {/* {width > 992 && <hr />} */}
                  </Col>
                  <Col xs={12} md={6}>
                    <Odom
                      number={500}
                      preText="$"
                      text="M"
                      format="(.ddd).dd"
                      style={style.general}
                      odomStyle={style.odomStyle}
                      textStyle={style.textStyle}
                      preTextStyle={style.preTextStyle}
                    />
                    <div className="description">
                      Commitment to achieve
                      aspirational targets
                    </div>
                    <hr />
                    <Odom
                      number={12}
                      preText=""
                      text=""
                      format="(.ddd).dd"
                      style={style.general}
                      odomStyle={style.odomStyle}
                      textStyle={style.textStyle}
                      preTextStyle={style.preTextStyle}
                    />
                    <div className="description">
                      Renewable Natural Gas projects in development
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <hr style={{ width: '25%' }} />
                    <div className="description" style={{ width: '75%' }}>
                      Lowest emissions intensity AMONG NATIONAL
                      SOLID WASTE PEERS
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={5}>
                <div className='accent'>
                  <img src={people} alt="" className='highlight' style={{ marginBottom: 0, height: '100%', width: 'auto' }} />
                </div>
                <Row>                                
                  <Col style={{ borderLeft: width > 922 ? '1px solid gray' : "none" }}>
                    <div style={{ fontName: 'Lato', fontSize: 44, fontWeight: 'bold', color: 'white', lineHeight: 1, marginBottom: 7, marginTop: 5 }}>
                      D&I
                    </div>
                    <div className="description">
                      DIVERSITY AND INCLUSION
                      SCORES INCLUDED IN
                      ANNUAL SERVANT
                      LEADERSHIP SURVEYS
                    </div>
                    <hr />
                    <div className="description">
                      OSHA “TRIR” SAFETY RECORD
                      BETTER THAN INDUSTRY
                      AVERAGE
                    </div>
                  </Col>
                </Row>

              </Col>
              <Col xs={12} lg={2}>
                <div className='accent'>
                  <img src={columns} alt="" className='highlight' style={{ marginBottom: 0, height: '100%', width: 'auto' }} />
                </div>
                <Row>
                  <Col style={{ borderLeft: width > 992 ? '1px solid gray' : "none" }}>
                    <div style={{ fontName: 'Lato', fontSize: 44, fontWeight: 'bold', color: 'white', lineHeight: 1, marginBottom: 7, marginTop: 5 }}>
                      ESG
                    </div>
                    <div className='description'>
                      TARGETS INCORPORATED
                      INTO MANAGEMENT
                      COMPENSATION
                    </div>
                    <hr />
                    <div className='description'>
                      BOARD OF DIRECTORS
                      OVERSIGHT OF ESG
                    </div>
                    <hr />
                    <div className='description'>
                      ROBUST ENVIRONMENTAL
                      POLICY
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link smooth to="/pdf-viewer#top">
                <div style={{ textAlign: 'center', border: '1px solid white', padding: '1rem' }}>
                  READ OUR {width < 501 && <br />}2023 SUSTAINABILITY REPORT
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: '#F1F1F2', paddingTop: '4rem', paddingBottom: '4rem' }}>
        <Container>
          <h2 style={{ textAlign: 'center', color: '#2561A0', marginBottom: '1em', fontSize: '2.75rem' }}>
            OUR ASPIRATIONAL ESG TARGETS
          </h2>
          <Row>
            <Col xs={12} xl={3}>
              <Row>
                <Col xs={12} md={6} xl={12}>
                  <div className={width <= 768 ? 'target-progress-item-container  no-border' : 'target-progress-item-container'} style={width > 1200 ? { paddingBottom: '8rem' } : {}}>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {  }}>
                      <div className="target-progress-item-header">
                        <img src={greenEarth} aria-label="earth with leaf" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title">EMISSIONS</div>
                      </div>
                      <div className='target-progress-item-odom-container'>
                        <Odom
                          number={30}
                          text="%"
                          format="(.ddd).dd"
                          style={targetProcessStyle.general}
                          odomStyle={targetProcessStyle.odomStyle}
                          textStyle={targetProcessStyle.textStyle}
                          preTextStyle={targetProcessStyle.preTextStyle}
                        />
                        <img src={greenUp} alt="decrease" className="target-progress-item-odom-container-arrow flipped" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Reduction in Scope 1 and 2 emissions
                      {/* </div> */}
                      <div style={{ ...targetProcessStyle.textStyle, paddingTop: 0, paddingBottom: 0, lineHeight: 1, margin: 0 }}>
                        +
                      </div>
                      {/* <div className='target-progress-item-detail'> */}
                        Continuous improvement in emissions intensity
                      </div>
                    </div>
                  </div>
                </Col>
                {width < 1200 && <Col xs={12} md={6}>
                  <div className={width <= 768 ? 'target-progress-item-container  no-border' : 'target-progress-item-container  no-border'}>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={recycling} aria-label="recyle symbol" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title">RECYCLING</div>
                      </div>
                      <div className='target-progress-item-odom-container'>
                        <Odom
                          number={50}
                          text="%"
                          format="(.ddd).dd"
                          style={targetProcessStyle.general}
                          odomStyle={targetProcessStyle.odomStyle}
                          textStyle={targetProcessStyle.textStyle}
                          preTextStyle={targetProcessStyle.preTextStyle}
                        />
                        <img src={greenUp} alt="increase" className="target-progress-item-odom-container-arrow" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Increase resources recovered by at least 50%
                      </div>
                    </div>
                  </div>
                </Col>}
              </Row>
            </Col>


            <Col xs={12} xl={9}>
              <Row>
                {width >= 1200 && <Col xs={12} md={6} xl={4}>
                  <div className={width <= 768 ? 'target-progress-item-container  no-border' : 'target-progress-item-container'}>
                    <div className="target-progress-item">
                      <div className="target-progress-item-header">
                        <img src={recycling} aria-label="recyle symbol" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title">RECYCLING</div>
                      </div>
                      <div className='target-progress-item-odom-container'>
                        <Odom
                          number={50}
                          text="%"
                          format="(.ddd).dd"
                          style={targetProcessStyle.general}
                          odomStyle={targetProcessStyle.odomStyle}
                          textStyle={targetProcessStyle.textStyle}
                          preTextStyle={targetProcessStyle.preTextStyle}
                        />
                        <img src={greenUp} alt="increase" className="target-progress-item-odom-container-arrow" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Increase resources recovered by at least 50%
                      </div>
                    </div>
                  </div>
                </Col>}
                <Col xs={12} md={6} xl={4}>
                  <div className={width <= 768 ? 'target-progress-item-container  no-border' : 'target-progress-item-container'}>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={bioGas} aria-label="gas tank" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title">BIOGAS<br />
                          RECOVERY</div>
                      </div>
                      <div className='target-progress-item-odom-container' style={{ marginTop: 10 }}>
                        <Odom
                          number={40}
                          text="%"
                          format="(.ddd).dd"
                          style={targetProcessStyle.general}
                          odomStyle={targetProcessStyle.odomStyle}
                          textStyle={targetProcessStyle.textStyle}
                          preTextStyle={targetProcessStyle.preTextStyle}
                        />
                        <img src={greenUp} alt="increase" className="target-progress-item-odom-container-arrow" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Increase biogas recovery by at least 40%
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <div className='target-progress-item-container no-border'>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={leachate} aria-label="gas tank" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title">LEACHATE</div>
                      </div>
                      <div className='target-progress-item-odom-container'>
                        <Odom
                          number={50}
                          text="%"
                          format="(.ddd).dd"
                          style={targetProcessStyle.general}
                          odomStyle={targetProcessStyle.odomStyle}
                          textStyle={targetProcessStyle.textStyle}
                          preTextStyle={targetProcessStyle.preTextStyle}
                        />
                        <img src={greenUp} alt="increase" className="target-progress-item-odom-container-arrow" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Process at least 50% of Leachate on-site
                      </div>
                    </div>
                  </div>
                </Col>
                {/* </Row>
          <Row> */}
                <Col xs={12} md={6} xl={4}>
                  <div className={width <= 768 ? 'target-progress-item-container  no-border' : 'target-progress-item-container'}>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={incidentRate} aria-label="recyle symbol" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title blue">INCIDENT<br />RATE</div>
                      </div>
                      <div className='target-progress-item-odom-container'>
                        <Odom
                          number={25}
                          text="%"
                          format="(.ddd).dd"
                          style={{ ...targetProcessStyle.general, ...targetProcessStyle.blue }}
                          odomStyle={{ ...targetProcessStyle.odomStyle, ...targetProcessStyle.blue }}
                          textStyle={{ ...targetProcessStyle.textStyle, ...targetProcessStyle.blue }}
                          preTextStyle={{ ...targetProcessStyle.preTextStyle, ...targetProcessStyle.blue }}
                        />
                        <img src={blueDown} alt="decrease" className="target-progress-item-odom-container-arrow" />
                      </div>
                      <div className='target-progress-item-detail'>
                        Reduction in Incident Rate
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <div className='target-progress-item-container' style={width < 1200 ? { borderRight: 'none' } : {}}>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={voluntaryTurnover} aria-label="gas tank" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title blue">VOLUNTARY<br />TURNOVER</div>
                      </div>
                      <div className='target-progress-item-odom-container top'>
                        <img src={blueDown} alt="decrease" className="target-progress-item-odom-container-arrow" />
                        <div className='target-progress-item-detail'>
                          Continuous improvement<br />
                          in Voluntary Turnover<br />
                          scores
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={12} xl={4}>
                  <div className='target-progress-item-container no-border'>
                    <div className="target-progress-item" style={width < 1200 ? { width: 350 } : {}}>
                      <div className="target-progress-item-header">
                        <img src={servantLeadership} aria-label="gas tank" height="64px" width="auto" className="target-progress-item-icon" />
                        <div className="target-progress-item-title blue">SERVANT<br />LEADERSHIP</div>
                      </div>
                      <div className='target-progress-item-odom-container top'>
                        <img src={blueUp} alt="increase" className="target-progress-item-odom-container-arrow" />
                        <div className='target-progress-item-detail'>
                          Continuous improvement
                          in Servant Leadership
                          scores
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </Container>
      </div>
      <Container className="links">
        <Container className="fifth-grid">
          <Container className="content links-grid">
            <div className="links-grid-item">
              <a download href={esPdf} alt="download the es report">
                <div className="cover sustainability">
                  <strong>2023</strong>
                  <hr />
                  SUSTAINABILITY
                  REPORT
                </div>
                <div className='download'>
                  Download »
                </div>
              </a>
            </div>
            <div className="links-grid-item">
              <Link smooth to="/environmental#top">
                <div className="cover sasb">
                  ENVIRONMENTAL
                  STEWARDSHIP
                  <div style={{ backgroundColor: '#6ABE54', height: 3, width: '4rem', marginTop: '.5rem' }} />
                </div>
                <div className='download'>
                  Learn More »
                </div>
              </Link>
            </div>
            <div className="links-grid-item">
              <Link smooth to="/social#top">
                <div className="cover tcfd">
                  EMPLOYEE
                  AND COMMUNITY ENGAGEMENT
                  <div style={{ backgroundColor: '#429CD7', height: 3, width: '4rem', marginTop: '.5rem' }} />
                </div>
                <div className='download'>
                  Learn More »
                </div>
              </Link>
            </div>
            <div className="links-grid-item">
              <Link smooth to="/governance#top">
                <div className="cover gri">
                  CORPORATE
                  GOVERNANCE
                  <div style={{ backgroundColor: '#9CA5AA', height: 3, width: '4rem', marginTop: '.5rem' }} />
                </div>
                <div className='download'>
                  Learn More »
                </div>
              </Link>
            </div>

          </Container>
          <div className="links-grid-item">
            <Link smooth to="/esg-data-hub#top">
              <div className="cover esg-bg">
                ESG FRAMEWORKS<br />
                AND<br />
                ADDITIONAL DATA
                <div style={{ backgroundColor: '#9CA5AA', height: 3, width: '4rem', marginTop: '.5rem' }} />
              </div>
              <div className='download'>
                Learn More »
              </div>
            </Link>
          </div>
        </Container>

      </Container>
      <Container className="news" fluid>
        <Container className="content">
          <Row>
            <div className="title">
              <img src={newsIcon} alt="" />
              <div>
                Sustainability News
              </div>
            </div>
          </Row>
        <hr />
        <Row>
            <Col xs={12} md={6}>
            <div className="newsFeature">
                <img src={cardboardRecycling} alt="" />
            </div>
            </Col>
            <Col xs={12} md={6}>
                <div className="newsFeature">
                    <div className="title">
                      Georgia-Pacific Recycling and Waste Connections Extend Partnership to Improve Capture and Reuse of Recyclables
                    </div>
                    <div>
                        Georgia-Pacific Recycling and affiliates of Waste Connections, Inc. announced a multi-year extension of their Recyclable Material Master Purchase Agreement,
                        supporting continued advancement of the circularity of recyclable commodities through collection and reuse.
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>                                  
                        <div style={{ textAlign: 'center', border: '1px solid white', padding: '1rem', color:'white' }}>
                            <a href="https://www.prnewswire.com/news-releases/georgia-pacific-recycling-and-waste-connections-extend-partnership-to-improve-capture-and-reuse-of-recyclables-301738868.html" aria-label="Navigate to news site" target="_blank">READ MORE</a>
                        </div>                        
                    </div>
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                <div className="newsCard">
                    <img src={lionElectricNews} alt="A waste collection truck picks up a garbage bin" />
                    <div>
                       Waste Connections discusses PFAS leachate remediation deal with Altra Sanexen
                    </div>
                        <a href="https://www.wastedive.com/news/waste-connections-pfas-leachate-altra-sanexen/650974/" aria-label="Navigate to news site">READ MORE »</a>
                </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="newsCard">
                <img src={safetyCulture} alt="A truck driver talks to a colleague" />
                <div>
                    The Value of a Strong Safety Culture <br /><br />Safety was on display during Waste Connections’ one-year acquisition anniversary of J&J Services in Nashville, Tenn.
                </div>
                    <a href="https://www.wasterecyclingmagazine-digital.com/nwraq/0123_winter_2023/MobilePagedReplica.action?pm=1&folio=26#pg26" aria-label="Navigate to news site" target="_blank">READ MORE »</a>
              </div>
            </Col>
            <Col xs={12} md={3}>
                <div className="newsCard">
                    <img src={wasteConnectionsGroupNews} alt="A truck driver waves" />
                    <div>
                        Waste Connections Continues Progress Towards Aspirational ESG Targets And Introduces Emission Reduction Targets In 2022 Sustainability Report
                    </div>
                    <a href="https://investors.wasteconnections.com/2022-10-27-WASTE-CONNECTIONS-CONTINUES-PROGRESS-TOWARDS-ASPIRATIONAL-ESG-TARGETS-AND-INTRODUCES-EMISSION-REDUCTION-TARGETS-IN-2022-SUSTAINABILITY-REPORT" aria-label="Navigate to news site">READ MORE »</a>
                </div>
            </Col>            
            <Col xs={12} md={3}>
              <div className="newsCard">
                <img src={ampRoboticsNews} alt="A robotic arm interacts with a conveyor belt" />
                <div>
                   AMP Robotics and Waste Connections Reach Recycling Technology Milestone
                </div>
                <a href="https://www.amprobotics.com/news-articles/amp-robotics-and-waste-connections-reach-recycling-technology-milestone" aria-label="Navigate to news site">READ MORE »</a>
              </div>
            </Col>            
          </Row>
        </Container>
      </Container>
    </PageWrapper>
  )
}

export default HomePage;
