import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import { HashLink as Link } from 'react-router-hash-link';

import PageWrapper from '../components/PageWrapper';
import rjmittelstaedt from '../assets/images/MittelstaedtR.jpg';
//import wJackmanSignature from '../assets/images/wJackmanSignature.png';
import twentyFiveYear from '../assets/images/twentyFiveYear.png';
import barUp from '../assets/images/barUp.png';
import barDown from '../assets/images/barDown.png';
import Odom from '../components/Odom';

const CeoLetterPage = (props) => {
  const { width, isMobile } = props;

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <Container className='ceoLetter' fluid>
        <Row className='content'>
          {width > 767 && <Headshot />}
          <Col xs={12} sm={12} md={7} lg={8} className="intro">
            <h1>
              LETTER FROM THE CHIEF EXECUTIVE OFFICER
            </h1>
            <br />
            <div className="subheader">
              Thank you for your interest in Waste Connections and our ongoing efforts toward sustainability-related objectives, which we consider integral to our business and aligned with value creation for our stakeholders.
            </div>
            <br />
            <br />
            <p>
              Our investments in resource recovery through recycling and renewable natural gas (RNG) facilities, leachate processing systems and PFAS treatment not only drive financial returns but also benefit the environment and the communities we have the privilege to serve. Moreover, our servant leadership-based, safety-oriented culture empowers our frontline employees through a decentralized operating structure and celebrates the benefits of an inclusive, supportive environment.
            </p>
            <p>
              In 2022, we demonstrated significant progress towards achievement of our sustainability-related objectives, most notably delivering a 14% reduction in Scope 1 and 2 emissions in spite of outsized revenue growth, resulting in a 27% reduction in emissions intensity. Following this progress, we are resetting the bar to double our targeted absolute emissions reductions to 30%. We also formally committed to setting an emissions reduction target with the Science-Based Target Initiative (SBTi) and we look forward to providing additional details as we work through the process of harmonizing targets to achieve a formal approval.
            </p>
            <p>
              We also increased our operational offsets by 8% in 2022, primarily driven by an 18% increase in recycling tons, bringing our total annual recycling tons processed to over two million. Our growth in offsets combined with our absolute reduction in emissions increased our ratio of operational offsets to emissions to over four times, further decarbonizing our operations.
            </p>
            <p>
              Looking ahead, we are positioned to significantly expand our biogas recovery through the development of additional RNG facilities projected to drive approximately $200 million of annual EBITDA by 2026 on a commensurate capital outlay.
            </p>
            <p>
              In addition, we continued to demonstrate our leadership in on-site leachate treatment, including addressing the challenges of PFAS treatment. By engaging with leading environmental and water solutions providers to deploy foam fractionation and other technologies, we are proactively treating landfill leachate to reduce PFAS concentrations, an approach that could become a future standard.
            </p>
            <p>
              At Waste Connections, we maintain that an emphasis on human capital ultimately sets us apart and drives our results, financially and operationally, including with respect to sustainability-related objectives. As such, we focus on sustaining the culture that has been a differentiator for Waste Connections and has driven over 25 years of performance. As we continue to grow, we will maintain our emphasis on human capital, including engagement and retention, and look forward to driving continuous improvement in metrics that are already better than industry average for safety, our most important operating value.
            </p>
            <p>
              While sustainability efforts are not new to Waste Connections, we continue to refine and expand our disclosure, including in our latest update. Following 2022's introduction to the people driving these results at Waste Connections, our 2023 report provides updates to the framework for Task Force for Financial Disclosure (TCFD), as well as environmental justice (EJ), Sustainability Accounting Standards Board (SASB), and Global Reporting Initiative (GRI) data. We continue to challenge ourselves toward the achievement of long-term aspirational targets as we work to drive value every day. We encourage you to review the 2023 Sustainability Report and to visit our Sustainability Hub for additional details.
            </p>
            <p>
              Once again, we sincerely appreciate your interest in sustainability at Waste Connections.
            </p>
            <br />
            {/*<img src={wJackmanSignature} alt="" className='signature' />*/}
            <div className='description'>
              <strong>
               Ronald J. Mittelstaedt
              </strong>
              <br />
              President and Chief Executive Officer
            </div>
            <br />
            <br />
          </Col>
          {width <= 767 && <Headshot />}
        </Row>
      </Container>
    </PageWrapper>
  )
}

export default CeoLetterPage;

const Headshot = () => (
  <Col xs={12} sm={12} md={5} lg={4} className='portrait'>
    <Row>
      <img src={rjmittelstaedt} aria-label="Headshot of President and CEO Ronald J. Mittelstaedt" />
      <br />
      <br />
    </Row>
    <Row>
      <Col>
        <hr />
        <p>
          SUSTAINABILITY EFFORTS ARE INTEGRAL TO OUR BUSINESS, DRIVEN BY AN INTENTIONAL FOCUS AND INVESTMENTS THAT ARE CONSISTENT WITH OUR OBJECTIVE OF LONG-TERM VALUE CREATION AND PART OF WHO WE ARE AS WASTE CONNECTIONS.
        </p>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 style={{ fontWeight: 'bold', color: '#0161A5', marginBottom: 0, lineHeight: 1, marginTop: '.5rem' }}>
          2022 Highlights
        </h2>
      </Col>
    </Row>
    <Row>
      <Highlight
        arrow
        preText={<img src={barDown} alt="Decrease" style={{ marginTop: '1.75rem' }} />}
        postText="%"
        number={14}
        detail="Reduction in Scope 1 and 2 emissions"
        className="bg1"
      />
      <Highlight
        preText={<img src={barDown} alt="Decrease" style={{ marginTop: '1.75rem' }} />}
        postText="%"
        number={27}
        detail="Reduction in Scope 1 and 2 emissions intensity"
        className="bg2"
      />
    <Highlight
        arrow
        preText={<img src={barUp} alt="Increase" style={{ marginTop: '1.75rem' }} />}
        postText="%"
        number={17}
        detail="Revenue Growth"
        className="bg3"
    />
    <Highlight        
        preText="~$"
        postText="M"
        number={640}
        detail="Acquired annualized revenue"
        className="bg4"
    />
      <Highlight
        preText="~"
        postText="bps"
        number={1,600}
        detail="Total shareholder return above the S&P 500 in 2022"
        className="bg5"
      />
      <Highlight
        preText="~$"
        postText="M"
        number={668}
        detail="Returned to shareholders"
        className="bg1"
      />      
    </Row>
  </Col>
)

const Highlight = ({ xs = 12, xl = 6, preText, postText, number, detail, className }) => (
  <Col xs={xs} xl={xl}>
    <div className={"highlight " + className}>
      <Odom
        number={number}
        preText={preText}
        text={postText}
        format="dd"
        style={{
          height: 72,
          marginBottom: 8,
        }}
        preTextStyle={{
          fontSize: 40,
          fontWeight: 'bold',
          color: 'white',
          paddingTop: 20
        }}
        odomStyle={{
          fontSize: 48,
          fontWeight: 'bold',
          color: 'white',
        }}
        textStyle={{
          fontSize: 44,
          fontWeight: 'bold',
          color: 'white',
          paddingTop: 22
        }}
      />
      <hr />
      <div className="detail">
        {detail}
      </div>
    </div>

  </Col>
)
