import React from 'react';
import { Col } from 'react-bootstrap';

const Button = ({ title, targetPage = 1, onClick = () => {}, pickedTargetPage }) => {

  return (
    <Col 
    xs={12}
    sm={6}
    md={4}
    className="pdfViewerButton"
    >
      <button onClick={() => onClick(targetPage)} className={pickedTargetPage === targetPage ? "chosenProxy" : "proxy"}>
        {title}
      </button>
    </Col>
  )
}

export default Button;