import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, Row, Col } from 'react-bootstrap';

import footerLogo from '../assets/images/footerLogo.png';


const Footer = () => {


  return (
    <footer>
      <div className='footerContent'>
        <div className='footerRow'>
          <Container>
            <Row>
              <Col xs={6} md={3}>
              <br /><Link smooth to="/#top" className="quickLinks">
                  HOME
                </Link><br /><br />
                <br /><Link smooth to="/environmental#top" className="quickLinks">
                  ENVIRONMENTAL
                </Link><br /><br />
              </Col>
              <Col xs={6} md={3}>
              <br /><Link smooth to="/pdf-viewer#top" className="quickLinks">
                  ESG REPORT
                </Link><br /><br />
                <br /><Link smooth to="/governance#top" className="quickLinks">
                  GOVERNANCE
                </Link><br /><br />
              </Col>
              <Col xs={6} md={3}>
              <br /><Link smooth to="/esg-data-hub#top" className="quickLinks">
                  ADDITIONAL MATERIALS
                </Link><br /><br />
                <br /><Link smooth to="/social#top" className="quickLinks">
                  SOCIAL
                </Link><br /><br />
              </Col>
              <Col xs={6} md={3}>
              <br /><a href="https://investors.wasteconnections.com/" aria-label="Investor Relations" className="quickLinks">
                  INVESTOR RELATIONS
                </a><br /><br />
              </Col>
            </Row>
          </Container>
          <div className="logo">
            <img src={footerLogo} width="100px" height="auto" aria-label="Waste Connections" />
          </div>
        </div>
        <div className='footerRow'>
          <hr />
        </div>
        <div className='footerRow'>

          <div className="copyright">
            Copyright © 2022 Waste Connections
          </div>
          <div className='socialMedia'>
            <div>
              <a href="https://www.facebook.com/wasteconnections/" target="_blank" rel="noreferrer" aria-label="Open waste connections Facebook page in a new tab">
                <img className="wcn-icon" src="https://s3.amazonaws.com/cdn.wasteconnections.com/images/SocialMedia/facebook/Facebook+Brand+Asset+Pack+2019/f-Logos-2019-1/f_Logo_Online_04_2019/white/PNG/f_logo_RGB-White_100.png" alt="Facebook" />
              </a>
              <a target="_blank" href="https://twitter.com/WasteConnection" rel="noreferrer" aria-label="Open waste connections Twitter page in a new tab">
                <img className="wcn-icon" src="https://s3.amazonaws.com/cdn.wasteconnections.com/images/SocialMedia/Twitter/twitter-logo-01282021/Twitter+social+icons/Twitter+social+icons+-+rounded+square/Twitter+social+icons+-+rounded+square+-+white.png" alt="Twitter" />
              </a>
              <a target="_blank" href="https://www.instagram.com/official_waste_connections/" rel="noreferrer" aria-label="Open waste connections Instagram page in a new tab">
                <img className="wcn-icon" src="https://s3.amazonaws.com/cdn.wasteconnections.com/images/SocialMedia/IG/IG_white2.png" alt="Instagram" />
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCR7LPN_jA03c2ece0A17hQg" rel="noreferrer" aria-label="Open waste connections YouTube page in a new tab">
                <img className="wcn-icon" src="https://s3.amazonaws.com/cdn.wasteconnections.com/images/SocialMedia/Youtube/social/Youtube_white.png" alt="YouTube" />
              </a>
            </div>
            {/* <img src={facebookFooter} height="16em" width="16em" aria-label='facebook' />
            <img src={instaFooter} height="16em" width="16em" aria-label='instagram' /> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
