import React, { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container } from 'react-bootstrap';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const options = {
  layout: {
    padding: 44
  },
  animation: {
    duration: 5000
  },
  cutout: "61.8%",
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      display: false
    },
    title: {
      display: false,
      text: 'ETHNIC/RACIAL DIVERSITY IN OUR WORKFORCE',
    },
    tooltip: {
      enabled: true
    },
    datalabels: {
      display: false
    },
  },
  maintainAspectRatio: false
};

// const labels = ['Caucasion', 'Hispanic / Latinx', 'Black / African American', 'Multiracial', 'American Indian / Alaskan', 'Asian', 'Native Hawaiian / Pacific Islander'];

const BinaryDonut = ({ data, label, subtitle, labels, minText, width }) => {
  const [showDonut, setShowDonut] = useState(false);
  const [showLabels, setShowLabels] = useState(false);
  const chartRef = useRef(null);

  const binaryDonutData = {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor: ['#005FAD', "#C4C4C6"],
        borderWidth: 0,
        weight: .5
      },
    ],
  };

  useEffect(() => {
    const triggerAnimation = () => {
      if (!showDonut && chartRef && chartRef.current && chartRef.current.getBoundingClientRect().top < window.innerHeight - 50) {
        setShowDonut(true);
        setTimeout(() => setShowLabels(true), 2500);
      }
    }
    window.addEventListener('scroll', triggerAnimation);
    triggerAnimation();
    return () => window.removeEventListener('scroll', triggerAnimation);
  }, [showDonut]);

  if (width < 500) return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
      <Container>
        <div className="donutBox binary" ref={chartRef} style={{ flex: 2, width: '100%', height: 400 }}>

          {showDonut && <div className='lid'>
            <div className="title binary" style={{ color: '#4E5055', top: '25%', fontSize: '1rem', width: '40%', textAlign: 'center', left: '30%', right: '30%' }}>
              {label}
            </div>
            {/* {data[0] === 75 ? <div className={showLabels ? 'donutLabel binary visible' : 'donutLabel binary'} style={{ top: '25%', left: '25%' }}>
              25%
            </div> : <div className={showLabels ? 'donutLabel binary visible' : 'donutLabel binary'} style={{ top: '78.5%', left: '44%' }}>
              75%
            </div>} */}
          </div>
          }
          {showDonut && <Doughnut options={options} data={binaryDonutData} />}
        </div>

        <div className={showLabels ? 'binaryDonutLabels visible' : 'binaryDonutLabels'} style={{ marginTop: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          <div style={{ flex: 2 }}>
            <div class="title">
              {data[0]}%
            </div>
            <div class="subtitle" style={{ minHeight: 20 }}>
              {subtitle}
            </div>
          </div>


          <div className="donutDetails" style={{ flex: 1 }}>
            {labels.map((label, idx) => <div className="legend">
              <div className="legendKey" style={{ "--color": idx === 0 ? '#005FAD' : "#595B60", width: '1rem', marginTop: '.5rem', height: '1rem', maxHeight: '1rem' }} />
              <div className="legendItem" style={{ color: '#595B60', fontSize: minText ? '.7rem' : '1.25rem', marginTop: '.5rem', maxWidth: '4rem' }}>
                {label}
              </div>
            </div>)}
          </div>
        </div>

      </Container>
    </div>
  )

  return (
    <Container style={{ display: 'flex', flexDirection: 'row', width: 400, height: 400 }}>
      <div className="donutBox binary" ref={chartRef} style={{ flex: 2, width: 400, height: 400 }}>

        {showDonut && <div className='lid'>
          <div style={{ height: '1px', width: '4rem', top: '30%', right: '.5rem', backgroundColor: '#4E5055', position: 'absolute' }} ></div>
          <div className="title binary" style={{ color: '#4E5055', top: '22%', fontSize: '1rem', maxWidth: '7rem', paddingLeft: '2.25rem' }}>
            {label}
          </div>
          {data[0] === 75 ? <div className={showLabels ? 'donutLabel binary visible' : 'donutLabel binary'} style={{ top: '33.5%', left: '22%' }}>
            25%
          </div> : <div className={showLabels ? 'donutLabel binary visible' : 'donutLabel binary'} style={{ top: '68.5%', left: '44%' }}>
            75%
          </div>}
        </div>
        }
        {showDonut && <Doughnut options={options} data={binaryDonutData} />}
      </div>

      <div className={showLabels ? 'binaryDonutLabels visible' : 'binaryDonutLabels'} style={{ flex: 1 }}>
        <div class="title">
          {data[0]}%
        </div>
        <div class="subtitle">
          {subtitle}
        </div>
        <hr />

        <div className="donutDetails">
          {labels.map((label, idx) => <div className="legend">
            <div className="legendKey" style={{ "--color": idx === 0 ? '#005FAD' : "#595B60", width: '1rem', marginTop: '.5rem', height: '1rem', maxHeight: '1rem' }} />
            <div className="legendItem" style={{ color: '#595B60', fontSize: minText ? '.7rem' : '1.25rem', marginTop: '.5rem', maxWidth: '4rem' }}>
              {label}
            </div>
          </div>)}
        </div>
      </div>

    </Container>

  );
}

export default BinaryDonut;
