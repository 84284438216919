import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import headerLogo from '../assets/images/headerLogo.png';
import hamburger from '../assets/images/hamburger.png';
// import { keyboardImplementationWrapper } from '@testing-library/user-event/dist/keyboard';

const BREAKPOINT = 992;

const Header = ({ width }) => {

  const [menuIsShown, setMenuIsShown] = useState(false);
  const [additionalDownloadsShown, setAdditionalDownloadsShown] = useState(false);

  useEffect(() => {
    if (menuIsShown && width > BREAKPOINT) {
      setMenuIsShown(false);
      setAdditionalDownloadsShown(false);
    }
  }, [width, menuIsShown]);

  function closeMenu() {
    setMenuIsShown(false);
    setAdditionalDownloadsShown(false);
    window.document.getElementById('root').removeEventListener('click', closeMenu)
  }

  const toggleMobileMenuOpen = (setGenericMenuShown = () => { }) => {
    const relevantMenu = setGenericMenuShown === setMenuIsShown ? menuIsShown : additionalDownloadsShown;
    if (relevantMenu) {
      setGenericMenuShown(false);
    } else {
      window.document.getElementById('root').removeEventListener('click', closeMenu);
      window.document.getElementById('root').addEventListener('click', closeMenu);
      setGenericMenuShown(true);
    }
  }

  const determineClassName = (key) => {
    // window.location.pathname
    // if(key.length === 0 && )
    if(key === '') {
        if(window.location.href.indexOf('ceo-letter') === -1 &&
        window.location.href.indexOf('environmental') === -1 &&
        window.location.href.indexOf('social') === -1 &&
        window.location.href.indexOf('governance') === -1 &&
        window.location.href.indexOf('esg-data-hub') === -1 &&
        window.location.href.indexOf('pdf-viewer') === -1){
          return 'underline';
        } else {
          return null;
        }
        
      }
    if(window.location.href.indexOf(key) !== -1 ) return 'underline';
    // console.log('window.location.href = ', window.location.href)
    // try {
    //   const regExResults = window.location.href.match(/\/#\/(\.*-?\w*)/)
    //   // console.log('regExResults = ', regExResults)
    //   let param = regExResults && regExResults.length > 1 ? regExResults[1] : null;
    //   if (param === 'pdfViewer') {
    //     const section = window.location.href.split('?section=')[1];
    //     param = section;
    //   }
      
    //   // console.log('param = ', param)
    //   if (param === key) return 'underline';
    // } catch (err) {
    //   console.log('err = ', err)
    // }

    return null;
  }

  if (width < BREAKPOINT) return (
    <header>
      <div className='headerContent'>
        <a href="https://www.wasteconnections.com/" aria-label="Navigate to Waste Connections primary site">
          <img src={headerLogo} aria-label="Waste Connections, Connect with the Future" />
        </a>
        <button onClick={() => toggleMobileMenuOpen(setMenuIsShown)} aria-label="Header navigation menu">
          <img src={hamburger} alt="" />
        </button>
      </div>
      {menuIsShown && <div className="mobileMenu" id="mobileHeader">
        <div className='noPad'>
          <Link smooth to="/#top" className={determineClassName('')}>
            HOME
          </Link>
        </div>
        <div>
          <Link smooth to="/ceo-letter#top" className={determineClassName('ceo-letter')}>
            CEO LETTER
          </Link>
        </div>
        <div>
          <Link smooth to="/environmental#top" className={determineClassName('environmental')}>
            ENVIRONMENTAL
          </Link>
        </div>
        <div>
          <Link smooth to="/social#top" className={determineClassName('social')}>
            SOCIAL
          </Link>
        </div>
        <div>
          <Link smooth to="/governance#top" className={determineClassName('governance')}>
            GOVERNANCE
          </Link>
        </div>
        <div>
          <Link smooth to="/esg-data-hub#top" className={determineClassName('esg-data-hub')}>
            ESG DATA HUB
          </Link>
        </div>
        {/* <div>
          <a href="/#top" title="Download the Sustainability Report">
            DOWNLOAD<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
          </a>
        </div> */}
        {/* <div>
          <a href="/" aria-label="Download sustainability report">
            Sustainability Report<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
          </a>
        </div>
        <div>
          <a href="/" aria-label="Download environmental report">
            Environmental<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
          </a>
        </div>
        <div>
          <a href="/" aria-label="Download social report">
            Social<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
          </a>
        </div>
        <div>
          <a href="/" aria-label="Download governance report">
            Governance<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
          </a>
        </div>
        <div className='noBorder previousYears'>
          PREVIOUS YEARS<br />SUSTAINABILITY REPORT
        </div>
        <ul>
          <li>
            <a href="/" aria-label="Download 2021 sustainability report" className=' padTopHalfEm'>
              2021<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
            </a>
          </li>
          <li>
            <a href="/" aria-label="Download 2020 sustainability report" className=' padTopHalfEm'>
              2020<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
            </a>
          </li>
          <li>
            <a href="/" aria-label="Download 2019 sustainability report" className=' padTopHalfEm'>
              2019<img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
            </a>
          </li>
        </ul> */}
      </div>
      }
    </header>
  )


  return (
    <header>
      <div className='headerContent'>
        <a href="https://www.wasteconnections.com/" aria-label="Navigate to Waste Connections primary site">
          <img src={headerLogo} aria-label="Waste Connections, Connect with the Future" />
        </a>
        <div className="menu">
          <div>
            <Link smooth to="/#top" className={determineClassName('')}>
              HOME
            </Link>
          </div>
          <div>
            <Link smooth to="/ceo-letter#top" className={determineClassName('ceo-letter')}>
              CEO LETTER
            </Link>
          </div>
          <div>
            <Link smooth to="/environmental#top" className={determineClassName('environmental')}>
              ENVIRONMENTAL
            </Link>
          </div>
          <div>
            <Link smooth to="/social#top" className={determineClassName('social')}>
              SOCIAL
            </Link>
          </div>
          <div>
            <Link smooth to="/governance#top" className={determineClassName('governance')}>
              GOVERNANCE
            </Link>
          </div>
          <div className='noBorder'>
            <Link smooth to="/esg-data-hub#top" className={determineClassName('esg-data-hub')}>
              ESG DATA HUB
            </Link>
          </div>
          {/* <div className='noBorder'>
            <button onClick={() => toggleMobileMenuOpen(setAdditionalDownloadsShown)}>
              ADDITIONAL MATERIALS <img className='download' src={downloadIcon} aria-label="Download the Sustainability Report" />
            </button>
            {additionalDownloadsShown && <div className='additionalMaterialsDropdown'>
              <a href="/" aria-label="Download sustainability report">
              Sustainability Report
              </a>
              <a href="/" aria-label="Download environmental report">
              Environmental
              </a>
              <a href="/" aria-label="Download social report">
                Social
              </a>
              <a href="/" aria-label="Download governance report">
                Governance
              </a>
              <div className='previousYears'>
                PREVIOUS YEARS SUSTAINABILITY REPORT
              </div>
              <ul>
                <li>
                  <a href="/" aria-label="Download 2021 sustainability report" className=''>
                    2021
                  </a>
                </li>
                <li>
                  <a href="/" aria-label="Download 2020 sustainability report" className=''>
                    2020
                  </a>
                </li>
                <li>
                  <a href="/" aria-label="Download 2019 sustainability report" className=''>
                    2019
                  </a>
                </li>
              </ul>
            </div>}
          </div> */}
        </div>
      </div>
    </header>
  )
}

export default Header;
