import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import PageWrapper from '../components/PageWrapper';

//import ourJourney from '../assets/images/ourJourney.png';
import download from '../assets/images/download.png';
import ourjourney from '../assets/images/ourjourney23.jpg';

import EEO_1_Report from '../assets/2021_EEO_1_Report.pdf';
import EJ_Analysis from '../assets/2022_EJ_Analysis.pdf';
import Audit_Committee_Charter from '../assets/Audit_Committee_Charter.pdf';
import Code_of_Conduct_and_Ethics from '../assets/Code_of_Conduct_and_Ethics.PDF';
import Compensation_Committee_Charter from '../assets/Compensation_Committee_Charter.pdf';
import Corporate_Governance_Guidelines_and_Board_Charter from '../assets/WCN. Corporate Governance Guidelines and Board Charter (10-24-23) (00112751xA3717).pdf';
import Nominating_and_Corporate_Governance_Committee_Board_Charter from '../assets/Nominating and Corporate Governance Committee Charter (00107150xA3717).pdf';
import Diversity_Policy_for_Board_and_Senior_Management from '../assets/Diversity_Policy_for_Board_and_Senior_Management.pdf';
import Environmental_Policy_Statement_English from '../assets/Environmental_Policy_Statement_English.pdf';
import TCFD_Framework from '../assets/TCFD Report - 2023.pdf';
import WasteConnections_2023_ESG from '../assets/WasteConnections_2023_Sustainability Report.pdf';
import WasteConnections_2022_ESG from '../assets/Waste Connections 2022 Sustainability Report 05102023.pdf';
import report2018 from '../assets/2018_Sustainability_Report.pdf';
import report2019 from '../assets/2019_Sustainability_Report.pdf';
import report2020 from '../assets/Waste_Connections_2020_Sustainability_Report.pdf';
import report2021 from '../assets/2021_sustainability_report_booklet.pdf';
import gri from '../assets/WasteConnections_2023_GRI.pdf';
import sasb from '../assets/WasteConnections_2023_SASB.pdf';
import presentation from '../assets/ESG_Presentation_2022.pdf';
import health from '../assets/WCN_Health_and_Safety_Policy_Statement.pdf';
//import WasteConnections_2022_ESG_Cybersecurity from '../assets/WasteConnections_2022_ESG_Cybersecurity.pdf';
import WasteConnections_ESG_Cybersecurity from '../assets/WasteConnections_2023_ESG_Cybersecurity.pdf';
import Forced_Labour_Report from '../assets/Joint Forced Labour and Child Labour Report.pdf';


const GovernancePage = (props) => {
  const { width, isMobile } = props;

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <div className={'socialEngagementHero environmental easg1'} />
      <div className="governanceTitle">
        <div className='content'>
          <h2>
            ESG DATA HUB
          </h2>
        </div>
      </div>
      <div className='esg-container'>
              <div className='content'>
            <Row>
                <Col xs={12} lg={8}>
                    <h3>
                     ESG FRAMEWORKS & REPORTS
                    </h3>
                    
                    <hr />
                    <br />
                    <br />
                </Col>
                <Col xs={12} lg={4}>
                    <h3>
                        OUR DISCLOSURE JOURNEY
                    </h3>
                </Col>
            </Row>
            <Container className="esg-links">
            <Row>
              <Col xs={12} lg={4}>
                <div className="sectionTitle">
                  » SUSTAINABILITY REPORTS
                </div>

                <ul>
                  <li>
                    <a href={WasteConnections_2023_ESG} alt="Download" download="WasteConnections_2023_Sustainability Report.pdf">
                      2023 Sustainability Report <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <div className='subSection'>
                      PAST SUSTAINABILITY REPORTS
                    </div>
                    <div>
                      <a href={WasteConnections_2022_ESG} alt="download" download="Waste Connections 2022 Sustainability Report 05102023.pdf">2022 <img src={download} alt="" /></a> <em /><a href={report2021} alt="download" download="2021 Sustainability Report Booklet.pdf">2021 <img src={download} alt="" /></a> <em /><a href={report2020} alt="download" download="Waste Connections 2020 Sustainability Report.pdf">2020 <img src={download} alt="" /></a> <em /> <a href={report2019} alt="download" download="2019 Sustainability Report.pdf">2019 <img src={download} alt="" /></a> <em></em> <a href={report2018} alt="download" download="2018 Sustainability Report.pdf">2018 <img src={download} alt="" /></a>
                    </div>
                  </li>
                  <li>
                    <a href={presentation} alt="Download" download="ESG Presentation 2022.pdf">
                      2022 ESG Investor Presentation <img src={download} alt="" />
                    </a>
                  </li>
                </ul>

                <div className="sectionTitle">
                  » OTHER REPORTS
                </div>
                <ul>
                  <li>
                    <a href={EJ_Analysis} alt="Download" download="2022 EJ Analysis.pdf">
                      Environmental Justice <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={EEO_1_Report} alt="Download" download="2021 EEO 1 Report.pdf">
                      EEO-1 Report <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={Forced_Labour_Report} alt="Download" download="Joint Forced Labour and Child Labour Report.pdf">
                    Joint Forced Labour and Child Labour Report <img src={download} alt="" />
                    </a>
                  </li>
                </ul>

                <div className="sectionTitle">
                  » BOARD CHARTERS
                </div>
                <ul>
                  <li>
                    <a href={Audit_Committee_Charter} alt="Download" download="Audit Committee Charter.pdf">
                      Audit Committee Charter <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={Compensation_Committee_Charter} alt="Download" download="Compensation Committee Charter.pdf">
                      Compensation Committee Charter <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={Corporate_Governance_Guidelines_and_Board_Charter} alt="Download" download="WCN. Corporate Governance Guidelines and Board Charter (10-24-23) (00112751xA3717).pdf">
                      Corporate Governance Guidelines and Board Charter <img src={download} alt="" />
                    </a>
                    </li>
                    <li>
                    <a href={Nominating_and_Corporate_Governance_Committee_Board_Charter} alt="Download" download="Nominating and Corporate Governance Committee Charter (00107150xA3717).pdf">
                        Nominating and Corporate Governance Committee Charter <img src={download} alt="" />
                    </a>
                    </li>
                </ul>
              </Col>
              <Col xs={12} lg={4}>
                <div className="sectionTitle">
                  » ESG FRAMEWORKS
                </div>
                <ul>
                  <li>
                    <a href={gri} alt="Download" download="WasteConnections 2022 GRI.pdf">
                      GRI <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={sasb} alt="Download" download="WasteConnections 2022 SASB.pdf">
                      SASB <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={TCFD_Framework} alt="Download" download="TCFD Framework.pdf">
                      TCFD <img src={download} alt="" />
                    </a>
                  </li>
                </ul>
                <div className="sectionTitle">
                  » POLICIES
                </div>
                <ul>
                  <li>
                    <a href={Environmental_Policy_Statement_English} alt="Download" download="Environmental Policy Statement English.pdf">
                      Environmental Policy <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={Code_of_Conduct_and_Ethics} alt="Download" download="Code of Conduct and Ethics.pdf">
                      Code of Conduct and Ethics Policy <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={health} alt="Download" download="WCN Health and Safety Policy Statement.pdf">
                      Health and Safety Policy <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={Diversity_Policy_for_Board_and_Senior_Management} alt="Download" download="Diversity Policy for Board and Senior Management.pdf">
                      Diversity Policy <img src={download} alt="" />
                    </a>
                  </li>
                  <li>
                    <a href={WasteConnections_ESG_Cybersecurity} alt="Download" download="WasteConnections_2022_ESG_Cybersecurity.pdf">
                      Cybersecurity Policy <img src={download} alt="" />
                    </a>
                  </li>
                </ul>
                </Col>
                <Col xs={12} lg={4}>
                    <div className='ourJourney'>
                        <div className='content'>
                            <img src={ourjourney} alt="Disclosure Timeline" />
                        </div>
                    </div>
                </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      {/* <div className='finePrint'>
        <div className='content'>
          The Securities and Exchange Commission (SEC) has not promulgated any rules about the presentation of, or metrics related to, certain ESG matters. Accordingly, the information we present may not be directly comparable to that presented by other companies. In addition, the information often is based on statistics or metrics that are estimates, makes assumptions based on developing standards that may change, and provides aspirational goals that are not intended to be promises or guarantees. Accordingly, investors should not place undue reliance on the information set forth in this section of our website.
        </div>
      </div> */}

    </PageWrapper>
  )
}

export default GovernancePage;
