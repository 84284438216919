import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';


import PageWrapper from '../components/PageWrapper';
import columns from '../assets/images/columns.png';
import BinaryDonut from '../components/BinaryDonut';
//import ourGovernance from '../assets/images/ourGovernance.png';
import ourGovernance from '../assets/images/corpgov_transparent.png';

const GovernancePage = (props) => {
  const { width, isMobile } = props;
  const [heroImg, setHeroImg] = useState('govBg3');

  //useEffect(() => {
  //  function cycleImage(lastImg) {
  //    let img = 'govBg1';
  //    switch (lastImg) {
  //      case 'govBg1':
  //        img = 'govBg3';
  //        break;
  //      // case 'govBg2':
  //      //   img = 'govBg3';
  //      //   break;
  //      case 'govBg3':
  //        img = 'govBg1';
  //        break;
  //      default:
  //        img = null;
  //    }
  //    setHeroImg(img);
  //    if (img) setTimeout(() => cycleImage(img), 5000)
  //  }

  //  cycleImage('govBg1');

  //  return () => cycleImage(null);
  //}, [])

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <div className={'socialEngagementHero environmental ' + heroImg} />
      <div className="governanceTitle">
        <div className='content'>
          <img src={columns} alt="" />
          <h2>
            GOVERNANCE <br />
            & ETHICS
          </h2>
        </div>
      </div>
      <div className='boardDiversity'>
        <div className='content'>
          <h3>
            BOARD DIVERSITY
          </h3>
          <div className="subHeader">
            In addition to seeking diverse policy-making experiences in business and other personal and professional characteristics, we strive to maintain a Board of Directors wih a diversity of backgrounds, including gender and ethnicity.
          </div>
          <div className="annotation">
            *As of October 2023
          </div>
          <Container>
            <Row>
              <Col xs={12} xl={4}>
                <BinaryDonut
                  data={[38, 63]}
                  label='BOARD GENDER DIVERSITY'
                  subtitle="OF OUR BOARD MEMBERS ARE FEMALE"
                  labels={['Female', 'Male']}
                  minText
                  width={width}
                />
              </Col>
              <Col xs={12} xl={4} >
                <BinaryDonut
                  data={[25, 75]}
                  label='BOARD NATIONALITY'
                  subtitle="OF OUR BOARD MEMBERS ARE CANADIAN"
                  labels={['Canada', 'U.S.']}
                  minText
                  width={width}
                />
              </Col>
              <Col xs={12} xl={4} >
                <BinaryDonut
                  data={[88, 12]}
                  label='BOARD INDEPENDENCE'
                  subtitle="OF OUR BOARD MEMBERS ARE INDEPENDENT"
                  labels={['Independent Members', 'Non Independent']}
                  minText
                  width={width}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='soundPrinciples'>
        <div className='content'>
          <div className='spContainer'>
            <h3 style={{ color: 'white' }}>
              OUR GOVERNANCE PRINCIPLES
            </h3>
            <br />
                      <br />
                      <div className='ourCommittees'>
                          <img src={ourGovernance} width="100%" height="auto" alt="" />
                      </div>
          </div>
          <br />
          <div className='flexCenter'>
            <Link to="/pdf-viewer#top?section=governance-and-ethics" alt="Nagivate to the pdf proxy viewer page" className="whiteBox">
              Learn More
            </Link>
          </div>
        </div>
      </div>
      <div className='ourCommittees'>
        <div className='content'>
          <Container className="fifth-grid">
            <Container className="content links-grid">
              <div className="links-grid-item">
                <a smooth target="_blank" rel="noreferrer" href="https://investors.wasteconnections.com/board-of-directors" className='ourCommittees-item directorBiographies'>
                  DIRECTOR
                  BIOGRAPHIES
                </a>
              </div>
              <div className="links-grid-item">
                <a smooth target="_blank" rel="noreferrer" href="https://investors.wasteconnections.com/board-committees-and-charters?item=9" className='ourCommittees-item auditComittee'>
                  AUDIT
                  COMMITTEE
                </a>
              </div>
              <div className="links-grid-item">
                <a smooth target="_blank" rel="noreferrer" href="https://investors.wasteconnections.com/board-committees-and-charters?item=5" className='ourCommittees-item compensationComittee'>
                  COMPENSATION
                  COMMITTEE
                </a>
              </div>
              <div className="links-grid-item">
                <a smooth target="_blank" rel="noreferrer" href="https://investors.wasteconnections.com/board-committees-and-charters?item=10" className='ourCommittees-item nominatingAnd'>
                  NOMINATING & CORPORATE
                  GOVERNANCE
                  COMMITTEE
                </a>
              </div>

            </Container>
            <div className="links-grid-item">
              <Link to="/esg-data-hub#top" alt="Nagivate to the esg data hub page" className='ourCommittees-item policiesAnd' >
                POLICIES &
                ESG RELATED GOVERNANCE
                DOCUMENTS
              </Link>
            </div>
          </Container>
        </div>
      </div>
    </PageWrapper>
  )
}

export default GovernancePage;
