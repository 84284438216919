import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

import PageWrapper from '../components/PageWrapper';
import Odom from '../components/Odom';
import Chart from '../components/Chart';
import FadeInOut from '../components/FadeInOut';
// import Overlay from '../components/Overlay';
import environmentalEngagement from '../assets/images/environmentalEngagement.png';
// import leachate from '../assets/images/leachate.png';
// import gasCollected from '../assets/images/gasCollected.png';
// import gasUsed from '../assets/images/gasUsed.png';
import vidSrc from '../assets/wcVid.mp4';
import recycleVidSrc from '../assets/amp_robotics-waste_connections.mp4';
import yourWasteBeneficiallyReused from '../assets/images/yourWasteBeneficiallyReused.png';
import recyclingCommitment from '../assets/images/recyclingCommitment.png';
import ctaEnviron1 from '../assets/images/ctaEnviron1.PNG';
import leachateManagement from '../assets/images/leachateManagement.png';
import zeroWaste from '../assets/images/zeroWaste.png';
import fleetEmissions from '../assets/images/fleetEmissions.png';
import environmentalManagement from '../assets/images/environmentalManagement.png';
import inTwentyTwo from '../assets/images/inTwentyTwoWeCommenced.png';
import alternativeFueledVehicles from '../assets/images/alternativeFueledVehicles.png';
import newEmissionReduction from '../assets/images/newEmissionReduction.png';
import fiveYearCommitment from '../assets/images/fiveYearCommitment.png';
import newLeachate from '../assets/images/newLeachate.svg';
import renewableNaturalGas from '../assets/images/bioGasWhite.svg';
import barDown from '../assets/images/barDown.png';

const EnvironmentalPage = (props) => {
  const { width, isMobile } = props;
  const [heroImg, setHeroImg] = useState('eeBg1');
  const [videoClass, setVideoClass] = useState('content');
  const vidRef = useRef();
  const vidRef2 = useRef();

  useEffect(() => {
    function cycleImage(lastImg) {
      let img = 'eeBg1';
      switch (lastImg) {
        case 'eeBg1':
          img = 'eeBg2';
          break;
        case 'eeBg2':
          img = 'eeBg3';
          break;
        case 'eeBg3':
          img = 'eeBg1';
          break;
        default:
          img = null;
      }
      setHeroImg(img);
      if (img) setTimeout(() => cycleImage(img), 5000)
    }

    cycleImage('eeBg1');

    return () => cycleImage(null);
  }, [])

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <div className={'socialEngagementHero environmental ' + heroImg} />
      <div className="environmentalEngagementTitle">
        <div className='content'>
          <img src={environmentalEngagement} alt="" />
          <h2>
            ENVIRONMENTAL<br />STEWARDSHIP
          </h2>
        </div>
      </div>
      <div className='glance'>
        <div className='content'>
          <h3 className='environmental' style={{ fontSize: width < 501 ? '2rem' : '2.75rem' }}>
            OUR ENVIRONMENTAL TARGET PROGRESS
          </h3>
          <br />
        <Container>
            <Row>
                <Col xs={12} xl={6} className="flexCol flexCenter">
                    <Chart
                        barTitle='EMISSIONS'
                        scale='MEASURED IN MT CO2E (MIL)'
                        vals={[6.06, 5.66, 5.65, 4.86, 4.24]}
                        titles={["2019 BASE YEAR", "2020", "2021", "2022", "TARGET"]}
                        baseColorOverride="#005C41"
                        targetColorOverride="#64C258"
                        titleColorOverride="#1D7258"
                        subtitleColorOverride="#1D7258"
                        heightOperand={32}
                        animationDelayOverride={.3}
                        containerStyle={{ padding: '1rem' }}
                        containerHeight={220}
                        toFixed={2}
                        addTargetText="-30%"
                    />
                </Col>
                <Col xs={12} xl={6} className="flexCol flexCenter">
                    <Chart
                        barTitle='EMISSIONS INTENSITY'
                        scale='MEASURED IN MT CO2E/ $M REVENUE'
                        vals={[1125, 1040, 919, 674, 550]}
                        titles={["2019 BASE YEAR", "2020", "2021", "2022", "TARGET"]}
                        baseColorOverride="#005C41"
                        targetColorOverride="#64C258"
                        titleColorOverride="#1D7258"
                        subtitleColorOverride="#1D7258"
                        heightOperand={.17}
                        animationDelayOverride={.3}
                        containerStyle={{ padding: '1rem' }}
                        containerHeight={220}
                        arrow={<img src={barDown} alt="less" width="24px" height="24px" />}
                        addTargetText="CONTINUOUS IMPROVEMENT"
                    />
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='RECYCLING'
                  scale='MEASURED IN TONS (MIL)'
                  vals={[1.54, 1.7, 1.78, 1.84, 2.18, 2.31]}
                  titles={["2018 BASE YEAR", "2019", "2020", "2021", "2022", "TARGET"]}
                  baseColorOverride="#005C41"
                  targetColorOverride="#64C258"
                  titleColorOverride="#1D7258"
                  subtitleColorOverride="#1D7258"
                  heightOperand={80}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  containerHeight={220}
                  toFixed={2}
                  addTargetText="+50%"
                />
              </Col>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='BIOGAS RECOVERY'
                  scale='MEASURED IN STANDARD CUBIC FEET (BIL)'
                  vals={[26.2, 26.6, 28.5, 29.2, 29.00, 36.7]}
                  titles={["2018 BASE YEAR", "2019", "2020", "2021", "2022","TARGET"]}
                  baseColorOverride="#005C41"
                  targetColorOverride="#64C258"
                  titleColorOverride="#1D7258"
                  subtitleColorOverride="#1D7258"
                  heightOperand={5}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  containerHeight={220}
                  toFixed={1}
                  addTargetText="+40%"
                />
              </Col>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='LEACHATE PROCESSED ON-SITE'
                  scale='MEASURED BY PERCENTAGE'
                  vals={width < 501 ? [33, 33, 38, 37, 50] : [32.7, 33.1, 37.6, 37.1, 38.5, 50.0]}
                  titles={["2018 BASE YEAR", "2019", "2020", "2021", "2022","TARGET"]}
                  baseColorOverride="#005C41"
                  targetColorOverride="#64C258"
                  titleColorOverride="#1D7258"
                  subtitleColorOverride="#1D7258"
                  heightOperand={3.8}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  text="%"
                  containerHeight={220}
                  fontSize=".875rem"
                  addTargetText="50%"
                  toFixed={width < 501 ? false : 1}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='flexCenter'>
                  <Link to="/pdf-viewer#top?section=environmental-stewardship" alt="PLACEHOLDER" className='blueBox'>
                    Learn More
                  </Link>
                </div>
              </Col>
            </Row>

          </Container>

        </div>
      </div>
      <div className='environmental-highlights-blue'>
        <div className='glance'>
          <div className='content'>
            <h3 className='environmental white' style={{ fontSize: width < 501 ? '2rem' : '2.75rem' }}>
              ENVIRONMENTAL HIGHLIGHTS
            </h3>
            <br />
            <Container>
              <Row>
                <Col xs={12} lg={6} xxl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item environment">
                      <img src={fiveYearCommitment} alt="" />
                      <Odom
                        number={4.2}
                        text="x"
                        preText=""
                        format="(.ddd).dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                        preTextStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        OPERATIONAL OFFSETS EXCEED EMISSIONS
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                        Our recycling services, beneficial reuse of landfill gas and carbon permanently sequestered in our landfills offset or avoided 4.2 times our emissions. Planned growth of these offsets should further reduce our carbon footprint.
                      </div>}
                  />
                </Col>
                <Col xs={12} lg={6} xxl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item environment">
                      <img src={newEmissionReduction} alt="" />
                      <Odom
                        number={30}
                        text="%"
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        DOUBLING OUR EMISSIONS REDUCTION TARGET
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                        In 2023, we increased our commitment to reduce emissions by doubling our Scope 1 and 2 reduction target to 30% from 15%.
                      </div>}
                  />
                </Col>
                <Col xs={12} lg={6} xxl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item environment">
                      <img src={inTwentyTwo} alt="" />
                      <Odom
                        number={2}
                        text=""
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        WE COMMENCED CONSTRUCTION ON TWO HIGHLY AUTOMATED RECYCLING FACILITIES
                      </div>
                    </div>
                  }
                    hover={
                      <div className='lookingAhead' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                            We are expanding recycling capabilities by constructing two recycling facilities in markets where we already collect recyclables, optimizing and vertically integrating our operations. These facilities will feature the newest MRF technology through the expanded use of optical sorters and robotics for enhanced productivity and product quality.
                      </div>
                    }
                  />
                </Col>

                <Col xs={12} lg={6} xxl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item environment">
                      <img src={renewableNaturalGas} alt="" style={{ marginBottom: -14, paddingBottom: -8, height: 64 }} />
                      <Odom
                        number={12}
                        text=""
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        RENEWABLE NATURAL GAS PLANTS IN DEVELOPMENT AND EXPECTED TO BE OPERATIONAL BY 2026
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                        With a growing pipeline of Renewable Natural Gas projects, we are well-positioned for achievement of our fifteen-year aspirational target to expand biogas recovery by 40% from 2018 levels.
                      </div>}
                  />
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <FadeInOut className="keyGoalWrapper" basic={
                        <div className="item environment">
                            <img src={newLeachate} alt="" style={{ marginBottom: -4, height: '3.5rem' }} />
                            <div style={{
                                fontSize: 64,
                                fontWeight: 'bold',
                                color: 'white',
                                lineHeight: 1.125,
                                paddingBottom: 4
                            }}>
                                PFAS
                            </div>
                            <div className='description'>
                                DEMONSTRATING LEADERSHIP
                            </div>
                        </div>}
                        hover={
                            <div className='lookingAhead social-flip' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                                We continued to demonstrate our leadership in on-site leachate treatment, including addressing the challenges of PFAS treatment.  By engaging with leading environmental and water solutions providers to deploy foam fractionation and other technologies, we are proactively treating landfill leachate to reduce PFAS concentrations, an approach that could become a future standard.
                            </div>
                        }
                    />
                </Col>
                <Col xs={12} lg={6} xxl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item environment">
                      <img src={alternativeFueledVehicles} alt="" style={{height: "2.5rem"}}/>
                      <Odom
                        number={1070}
                        text=""
                        format="d,ddd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        ALTERNATIVE FUELED
                        VEHICLES
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                        We currently operate over 1,000 CNG refuse vehicles, and have placed orders for fully electric trucks for beta testing.
                      </div>}
                  />

                </Col>

              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div className='ourVisionOfCircularity'>
        <h3 className='environmental green' style={{ fontSize: width < 501 ? '2rem' : '2.75rem' }}>
          OUR VISION OF THE CIRCULAR ECONOMY
        </h3>
        <br />
        <br />
        <img src={yourWasteBeneficiallyReused} aria-label="Your Waste Transformed into Energy: Natural Gas feed 1500 trucks or 20000 households per year, BioGas is collected from our landfills" className='ourVisionOfCircularity' />
      </div>

      <div className='glance'>
        <div className='content'>
          <h3 className='environmental' style={{ fontSize: width < 501 ? '2rem' : '2.75rem' }}>
            OUR ENVIRONMENTAL COMMITMENT
          </h3>
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12} style={{ marginBottom: '4rem' }}>
                <div className='commitment-item'>
                  <div className='top'>
                    <div className='glance'>
                        <div className='resourceRecovery'>
                            <video controls aria-label='Forêt®' ref={vidRef2}>
                                <source src={recycleVidSrc} type="video/mp4" />
                            </video>
                            <div className={videoClass}>
                                <h3 className='environmentalvideo black' style={{ fontSize: width < 740 ? '1.25rem' : '1.75rem' }}>
                                    WASTE CONNECTIONS & AMP ROBOTICS
                                </h3>
                                <div className='subheader' style={{ color: 'black' }}>
                                    Learn more about the technology
                                </div>
                                <br />
                                <br />
                                <button alt="Click to play video" onClick={() => {
                                    vidRef2.current.play();
                                    setVideoClass('content hidden');
                                }}>
                                    PLAY VIDEO
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='subtitle'>
                      RESOURCE RECOVERY
                    </div>
                    <div className='title'>
                      Recycling
                    </div>
                    <p>
                      With over 70 material recovery facilities, we process over two million tons of post-consumer plastics, fibers and metals, reducing the need for virgin materials.
                    </p>
                  </div>
                  <Link to="/pdf-viewer#top?section=recycling" alt="Nagivate to the pdf proxy viewer page">
                    READ MORE »
                  </Link>
                </div>
                          </Col>
                      </Row>
               

              <Row>                             
                    <Col xs={12} md={12} lg={12} style={{ marginBottom: '4rem' }}>
                        <div className='commitment-item'>
                            <div className='top'>
                                <div className='glance'>
                                    <div className='resourceRecovery'>
                                        <video controls aria-label='Forêt®' ref={vidRef}>
                                            <source src={vidSrc} type="video/mp4" />
                                        </video>
                                        <div className={videoClass}>
                                            <h3 className='environmentalvideo white' style={{ fontSize: width < 740 ? '1.25rem' : '1.75rem' }}>
                                                RESOURCE RECOVERY — BENEFICIAL REUSE OF LANDFILL GAS
                                            </h3>
                                            <div className='subheader'>
                                                Learn more about our process
                                            </div>
                                            <br />
                                            <br />
                                            <button alt="Click to play video" onClick={() => {
                                                vidRef.current.play();
                                                setVideoClass('content hidden');
                                            }}>
                                                PLAY VIDEO
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='subtitle'>
                                    RESOURCE RECOVERY
                                </div>
                                <div className='title'>
                                    Landfill Gas
                                </div>
                                <p>
                                    We have nearly 30 landfills with beneficial reuse systems, either through electrical generation or Renewable Natural Gas (RNG) recovery. These facilities generate electricity for households, fuel local industrial facilities, and/or create RNG that can power alternative fuel vehicles.
                                </p>
                            </div>
                            <Link to="/pdf-viewer#top?section=renewable-gas-recovery" alt="Nagivate to the pdf proxy viewer page">
                                READ MORE »
                            </Link>
                        </div>
                    </Col>
                </Row>

                <Row>
              <Col xs={12} md={6} lg={3} style={{ marginBottom: '4rem' }}>
                <div className='commitment-item'>
                  <div className='top'>
                    <img src={ctaEnviron1} alt="" />
                    <div className='subtitle'>
                      <br />
                    </div>
                    <div className='title'>
                      Leachate Management
                    </div>
                    <p>
                      Through our operations, we generate over 650 million gallons of leachate per year. Our fifteen-year aspirational target is to mitigate the environmental impact of transporting and disposing of leachate by enhancing our ability to treate over 50% of leachate in our own facilities.
                    </p>
                  </div>
                  <Link to="/pdf-viewer#top?section=leachate-management" alt="Nagivate to the pdf proxy viewer page">
                    READ MORE »
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={6} lg={3} style={{ marginBottom: '4rem' }}>
                <div className='commitment-item'>
                  <div className='top'>
                    <img src={zeroWaste} alt="" />
                    <div className='subtitle'>
                      <br />
                    </div>
                    <div className='title'>
                      Zero Waste<br /><br />
                    </div>
                    <p>
                      We regularly work with customers to increase resource recovery and facilitate their pursuit of zero waste goals. We provide services like composting of yard waste and food waste and support the introduction of new technologies, such as anaerobic digesters.
                    </p>
                  </div>
                  <Link to="/pdf-viewer#top?section=zero-waste" alt="Nagivate to the pdf proxy viewer page">
                    READ MORE »
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={6} lg={3} style={{ marginBottom: '4rem' }}>
                <div className='commitment-item'>
                  <div className='top'>
                    <img src={fleetEmissions} alt="" />
                    <div className='subtitle'>
                      <br />
                    </div>
                    <div className='title'>
                      Fleet Emissions & Efficiencies
                    </div>
                    <p>
                      We look to selectively utilize alternative fuel vehicles as part of our efforts to reduce fleet emissions. In addition to Compressed Natural Gas (CNG) trucks, we have been operating a hybrid electric vehicle in one market and look forward to testing fully electric collection trucks.
                    </p>
                  </div>
                  <Link to="/pdf-viewer#top?section=fleet-emissions" alt="Nagivate to the pdf proxy viewer page">
                    READ MORE »
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={6} lg={3} style={{ marginBottom: '4rem' }}>
                <div className='commitment-item'>
                  <div className='top'>
                    <img src={environmentalManagement} alt="" />
                    <div className='subtitle'>
                      <br />
                    </div>
                    <div className='title'>
                      Environmental Management System
                    </div>
                    <p>
                      We maintain a robust Environmental Management System (EMS) as part of our operating practices and risk mitigation strategy.
                    </p>
                  </div>
                  <Link to="/pdf-viewer#top?section=environmental-management-system" alt="Nagivate to the pdf proxy viewer page">
                    READ MORE »
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>

        </div>
      </div>


    </PageWrapper>
  )
}

export default EnvironmentalPage;
