import React, { useState, useEffect, useRef } from 'react';

import whiteUpArrow from '../assets/images/whiteUpArrow.png';

const Chart = ({ barTitle = 'Continuous Improvement in Servant Leadership Scores', scale = 'ON A SCALE FROM 3-5', vals = [4.1, 4.1, 4.2, 4.3, 5], titles, heightOperand = 20, baseColorOverride, targetColorOverride, text, animationDelayOverride, titleColorOverride, subtitleColorOverride, containerStyle = {}, containerHeight, arrow, fontSize, titleFontSize = '1.25rem', toFixed, addTargetText }) => {
  function setTitle(idx) {
    if (titles) return titles[idx];
    return idx === vals.length - 1 ? "Target" : 2018 + idx;
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '450px',
      width: '100%',
      ...containerStyle
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 5
      }}>
        <div className='barTitle' style={{ color: titleColorOverride || targetColorOverride, fontSize: titleFontSize }}>
          {barTitle}
        </div>
        <div className="barHR" style={{ backgroundColor: baseColorOverride }} />
        <div className="barScale" style={{ color: subtitleColorOverride || baseColorOverride }}>
          {scale}
        </div>
        <div style={{
          display: 'flex',
          width: '100%',
          height: containerHeight || 'auto',
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }}>
          {vals.map((val, idx) => <IndividualBar
            key={setTitle(idx)}
            title={setTitle(idx)}
            val={val}
            baseColorOverride={baseColorOverride}
            targetColorOverride={targetColorOverride}
            heightOperand={heightOperand}
            text={text}
            animationDelayOverride={animationDelayOverride}
            idx={idx}
            vals={vals}
            arrow={arrow}
            fontSize={fontSize}
            toFixed={toFixed}
          />)}
          {/* <IndividualBar title={"2018"} val={4.1} />
          <IndividualBar title="2019" val={4.1} />
          <IndividualBar title="2020" val={4.2} />
          <IndividualBar title="2021" val={4.3} />
          <IndividualBar title="Target" val={5} /> */}
        </div>
        
        {addTargetText && <div className="individualBarLabel" style={{marginTop: '1.5rem', color: titleColorOverride}}>
          TARGET: <strong style={{fontWeight: 'bold', paddingLeft: '.25rem' }}>{' '+addTargetText}</strong>
        </div>}
      </div>

    </div>
  );
}

const IndividualBar = ({ title, val, heightOperand = 20, baseColorOverride, targetColorOverride, text, animationDelayOverride, idx, vals, arrow, fontSize = '.875rem', toFixed }) => {
  const [height, setHeight] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    const triggerAnimation = () => {
      if (!height && heightOperand && chartRef && chartRef.current && chartRef.current.getBoundingClientRect().top < window.innerHeight) {
        setHeight(val * heightOperand)
      }
    }
    window.addEventListener('scroll', triggerAnimation);
    triggerAnimation();
    return () => window.removeEventListener('scroll', triggerAnimation);
  }, [setHeight, height, val, heightOperand]);

  // useEffect(() => {
  //   if (!height) {
  //     setTimeout(() => setHeight(val * 20), 1)
  //   };
  // }, [val, height]);

  const renderInside = () => {
    if (arrow && idx + 1 === vals.length) return arrow;
    if (val === 5) return <img className='barArrow' src={whiteUpArrow} alt="" />;
    let formattedVal = toFixed ? val.toFixed(toFixed) : val;
    return <>{formattedVal}{text && text}</>
  }

  return (
    <div className='individualBarContainer' ref={chartRef}>
      <div className='individualBarAnimationWrapper'>
        <div className='individualBar' style={{ height, backgroundColor: idx === vals.length - 1 ? targetColorOverride || '#50AADD' : baseColorOverride || '#1E61A6', '--delay': animationDelayOverride || val / 10, fontSize }}>
          {renderInside()}
        </div>
      </div>

      <div className="individualBarLabel">
        {title}
      </div>
    </div>
  )
}

export default Chart;
