import React, { useState, useEffect, useRef } from 'react';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';


const Odom = ({ number, text, odomStyle, textStyle, style, preText, preTextStyle, format, xs, animationStyle }) => {
  const [odoVal, setOdoVal] = useState(number === 1 ? 9 : 0);
  const odomRef = useRef(null);

  useEffect(() => {
    const triggerAnimation = () => {
      if (odoVal !== number && odomRef && odomRef.current && odomRef.current.getBoundingClientRect().top < window.innerHeight) {
        setOdoVal(number);
      };
    }
    window.addEventListener('scroll', triggerAnimation);
    triggerAnimation();
    return () => window.removeEventListener('scroll', triggerAnimation);
  }, [number, odoVal]);

  return (
    // <Col xs={xs}>
      <div ref={odomRef} style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        ...style
      }}>
        {preText && <p style={preTextStyle || odomStyle}>
          {preText}
        </p>}
        <div
          style={odomStyle}>
          <Odometer
            format={format || "d"}
            duration={2000}
            // selector='.my-numbers'
            value={odoVal}
            animation={animationStyle || "count"}
          />
        </div>
        {text && <p style={textStyle || odomStyle}>
          {text}
        </p>}
      </div>
    // </Col>
  );
}

export default Odom;
