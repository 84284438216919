import React, { useState } from 'react';

const FadeInOut = ({ basic = {}, hover = {}, className='fadeInOutContainer' }) => {
  const [isViewingDefault, setIsViewingDefault] = useState(true);

  return (
    <div className={className}>
      <div className={isViewingDefault ? "fadeInOutDiv opaque" : "fadeInOutDiv transparent"} onMouseEnter={() => setIsViewingDefault(false)}>
        {basic}
      </div>
      <div className={isViewingDefault ? "fadeInOutDiv transparent" : "fadeInOutDiv opaque"} onMouseLeave={() => setIsViewingDefault(true)}>
        {hover}
      </div>
    </div>
  )
}

export default FadeInOut;
