import React from 'react';

import Header from './Header';
import Footer from './Footer';


const MAX_BODY_WIDTH = 1400;
const HEADER_HEIGHT = 61;

const PageWrapper = ({ children, isMobile, width }) => {

  return (
    <div id="top">
      <Header MAX_BODY_WIDTH={MAX_BODY_WIDTH} HEADER_HEIGHT={HEADER_HEIGHT} isMobile={isMobile} width={width} />
      <main>
        {children}
      </main>
      <Footer MAX_BODY_WIDTH={MAX_BODY_WIDTH} width={width} />
    </div>
  )
}

export default PageWrapper;
