import React, { useState, useEffect } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import Environmental from './Environmental';
import CeoLetter from './CeoLetter';
import HomePage from './HomePage';
import SocialPage from './SocialPage';
import PdfViewer from './PdfViewer';
import GovernancePage from './Governance';
import EsgDataHub from './EnvironmentAndSocialGov';

export const generateDirectorPath = (directorName) => directorName.toLowerCase().replaceAll('.', '').replaceAll(' ', '-');

const Pages = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = window.orientation > -1 || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || width < 576;

  const setHeightAndWidthOnWindowChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      window.addEventListener('resize', setHeightAndWidthOnWindowChange);
    } else {
      window.removeEventListener('resize', setHeightAndWidthOnWindowChange);
    }
    return () => mounted = false;
  });

  return (
    <Router>
      <Routes>
        <Route path={`/esg-data-hub`} element={<EsgDataHub height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/governance`} element={<GovernancePage height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/environmental`} element={<Environmental height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/ceo-letter`} element={<CeoLetter height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/pdf-viewer`} element={<PdfViewer height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/social`} element={<SocialPage height={height} width={width} isMobile={isMobile} />} />
        <Route path={`/`} element={<HomePage height={height} width={width} isMobile={isMobile} />} />
      </Routes>
    </Router>
  )
}

export default Pages;
