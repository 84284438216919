import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';

import PageWrapper from '../components/PageWrapper';
import Odom from '../components/Odom';
import Chart from '../components/Chart';
// import Donut from '../components/Donut';
import FadeInOut from '../components/FadeInOut';
// import cultureMattersIcon from '../assets/images/cultureMattersIcon.png';
import womenInCleanTechLogo from '../assets/images/womenInCleanTechLogo.png';
import womenInTruckingLogo from '../assets/images/womenInTruckingLogo.png';
import ceoActionLogo from '../assets/images/ceoActionLogo.png';
// import givingBackIcon from '../assets/images/givingBackIcon.png';
import people from '../assets/images/people.png';
import barUp from '../assets/images/barUp.png';
import barDown from '../assets/images/barDown.png';
import sueNetherton from '../assets/images/sueNetherton.png';
import participationIn from '../assets/images/participationIn.png';
import discretionaryCovid from '../assets/images/discretionaryCovid.png';
import oneOperating from '../assets/images/oneOperating.png';
import membersIn from '../assets/images/membersIn.png';
import increaseTo from '../assets/images/increaseTo.png';
import totalScholarship from '../assets/images/totalScholarship.png';
import givingBack from '../assets/images/givingBack.png';
// import womenInCleanTech from '../assets/images/womenInCleanTech.png';
// import womenInTrucking from '../assets/images/womenInTrucking.png';
// import ceoAction from '../assets/images/ceoAction.png';

const SocialPage = (props) => {
  const { width, isMobile } = props;
  const [heroImg, setHeroImg] = useState('img1');

  useEffect(() => {
    function cycleImage(lastImg) {
      let img = 'img1';
      switch (lastImg) {
        case 'img1':
          img = 'img2';
          break;
        case 'img2':
          img = 'img3';
          break;
        case 'img3':
          img = 'img5';
          break;
        // case 'img4':
        //   img = 'img5';
        // break;
        case 'img5':
          img = 'img1';
          break;
        default:
          img = null;
      }
      setHeroImg(img);
      if (img) setTimeout(() => cycleImage(img), 5000)
    }

    cycleImage('img1');

    return () => cycleImage(null);
  }, [])

  return (
    <PageWrapper topId="top" width={width} isMobile={isMobile}>
      <div className={'socialEngagementHero ' + heroImg} />
      <div className="socialEngagementTitle">
        <div className='content'>
          <img src={people} alt="" />
          <h2>
            SOCIAL<br />ENGAGEMENT
          </h2>
        </div>
      </div>
      <div className='glance grey'>
        <div className='content'>
          <h3>
            OUR SOCIAL TARGET PROGRESS
          </h3>
          <br />
          <Container>
            <Row>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='INCIDENT RATE'
                  scale='INCIDENTS PER 200,000 WORK HOURS'
                  vals={[17.04, 17.35, 15.16, 15.15, 15.62, 12.78]}
                  titles={["2018", "2019", "2020", "2021", "2022","TARGET"]}
                  heightOperand={9}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  containerHeight={220}
                  toFixed={2}
                  arrow={<img src={barDown} alt="less" width="24px" height="24px" />}
                  addTargetText="-25%"
                  titleColorOverride="rgb(1, 97, 165)"
                />
              </Col>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='VOLUNTARY TURNOVER'
                  scale='MEASURED BY PERCENTAGE'
                  vals={[17.8, 17.8, 14.6, 21.1, 21.4, 14]}
                  titles={["2018", "2019", "2020", "2021", "2022", "TARGET"]}
                  heightOperand={8}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  text="%"
                  containerHeight={220}
                  arrow={<img src={barDown} alt="less" width="24px" height="24px" />}
                  addTargetText="Continuous Improvement"
                  titleColorOverride="rgb(1, 97, 165)"
                  fontSize={width < 501 ? '3vw' : undefined}
                />
              </Col>
              <Col xs={12} xl={4} className="flexCol flexCenter">
                <Chart
                  barTitle='SERVANT LEADERSHIP'
                  scale='ON A SCALE OF 1 - 5'
                  vals={[4.11, 4.13, 4.21, 4.24, 4.25, 5]}
                  titles={["2018", "2019", "2020", "2021", "2022", "TARGET"]}
                  heightOperand={40}
                  animationDelayOverride={.3}
                  containerStyle={{ padding: '1rem' }}
                  containerHeight={220}
                  arrow={<img src={barUp} alt="more" width="24px" height="24px" />}
                  addTargetText="Continuous Improvement"
                  titleColorOverride="rgb(1, 97, 165)"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='flexCenter'>
                  <Link to="/pdf-viewer#top?section=social-engagement" alt="PLACEHOLDER" className='blueBox'>
                    Learn More
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='glance'>
        <div className='content'>
          <Container>
            <Row>
              <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                <img src={sueNetherton} alt="Sue Netherton" width="100%" height="auto" />
              </Col>
              <Col xs={12} sm={6} md={8} lg={9} xl={10}>
                <div className="netherton-quote">
                  “Our employees' preferences and needs are constantly evolving. By listening to our employees, leveraging the principles of Servant Leadership and utilizing technology, we are able to emphasize our employees’ well-being and expand personal development.”
                </div>
                <br />
                <div className="netherton-title-container">
                  <div className="netherton-title-name">
                    Sue Netherton
                  </div>
                  <div className="netherton-title-desc">
                    Senior Vice President —  <br />
                    People, Training and Development
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className='social-highlights-blue'>
        <div className='glance'>
          <div className='content'>
            <h3 className='environmental white'>
              EMPLOYEE AND COMMUNITY
              ENGAGEMENT HIGHLIGHTS
            </h3>
            <br />
            <Container>
            <Row>
                <Col xs={12} md={6} xl={4}>
                    <FadeInOut className="keyGoalWrapper" basic={
                        <div className="item social-highlight">
                            <img src={oneOperating} alt="" style={{ marginBottom: 4 }} />
                            <div style={{
                                fontSize: 64,
                                fontWeight: 'bold',
                                color: 'white',
                                lineHeight: 1.125,
                                paddingBottom: 4
                            }}>
                                SAFETY
                            </div>
                            <div className='description'>
                                #1 OPERATING
                                VALUE
                            </div>
                        </div>}
                        hover={
                            <div className='lookingAhead social-flip' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                                We believe that safety is the responsibility of every employee and deploy a behavioral-based approach that is ingrained in our commitment-based, Servant Leadership culture. Our unique approach rewards success and at the same time demands accountability by holding employees and leaders accountable that exhibit or tolerate unsafe behavior.
                                <br />
                                <br />
                                <Link to="/pdf-viewer#top?section=safety" alt="Nagivate to the pdf proxy viewer page">
                                    LEARN MORE »
                                </Link>
                            </div>
                        }
                    />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item social-highlight">
                      <img src={participationIn} alt="" />
                      <Odom
                        number={90}
                        preText="~"
                        text="%"
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        PARTICIPATION IN OUR ANNUAL SERVANT LEADERSHIP SURVEY
                      </div>
                    </div>
                  }
                    hover={
                      <div className='lookingAhead social-flip'>
                        In 2006, we adopted the management concept known as Servant Leadership — a philosophy that has defined and differentiated our organization.
                        <br />
                        <br />
                        <Link to="/pdf-viewer#top?section=servant-leadership" alt="Nagivate to the pdf proxy viewer page">
                          LEARN MORE »
                        </Link>
                      </div>
                    }
                  />
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <FadeInOut className="keyGoalWrapper" basic={
                        <div className="item social-highlight">
                         <img src={discretionaryCovid} alt="" style={{ marginBottom: 4 }} />
                            <div style={{
                                fontSize: 64,
                                fontWeight: 'bold',
                                color: 'white',
                                lineHeight: 1.125,
                                paddingBottom: 4
                            }}>
                                AWARDS
                            </div>
                            <div className='description'>
                                EMPLOYEE-NOMINATED AWARDS
                            </div>
                        </div>}
                        hover={
                            <div className='lookingAhead social-flip' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                                Our team has responded and recognized Waste Connections externally with a series of employee-nominated awards, including Comparably's Best Leadership, Best CEO for Women, Best Company for Women, Best Company for Diversity, Best Sales Team and Best Perks and Benefits.
                            </div>
                        }
                    />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item social-highlight">
                      <img src={membersIn} alt="" />
                      <Odom
                        number={1500}
                        text="+"
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        MEMBERS IN THE WOMEN’S AND
                        VETERANS RESOURCE GROUPS
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead social-flip'>
                        Our affinity and network groups help to support the development of our employees and expand diversity and inclusion.
                      </div>}
                  />

                </Col>
                <Col xs={12} md={6} xl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item social-highlight">
                      <img src={increaseTo} alt="" />
                      <Odom
                        number={40}
                        preText="+"
                        text="%"
                        format="dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                        preTextStyle={{
                          fontSize: 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        INCREASE TO TRAINING SESSIONS OFFERED IN 2022
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead social-flip'>
                        Continual learning opportunities benefit our workforce both personally and professionally and have been a cornerstone of our organization’s culture
                        for years.
                        <br />
                        <br />
                        <Link to="/pdf-viewer#top?section=employee-training" alt="Nagivate to the pdf proxy viewer page">
                          LEARN MORE »
                        </Link>
                      </div>}
                  />
                </Col>
                <Col xs={12} md={6} xl={4}>
                  <FadeInOut className="keyGoalWrapper" basic={
                    <div className="item social-highlight">
                      <img src={totalScholarship} alt="" />
                      <Odom
                        number={1.75}
                        text="M"
                        preText="$"
                        format="(.ddd).dd"
                        style={{
                          height: 72,
                          marginBottom: 8,
                        }}
                        odomStyle={{
                          fontSize: width < 501 ? 48 : 64,
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                        textStyle={{
                          fontSize: width < 501 ? 48 : 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                        preTextStyle={{
                          fontSize: width < 501 ? 48 : 64,
                          fontWeight: 'bold',
                          color: 'white',
                          paddingTop: 24
                        }}
                      />
                      <div className='description'>
                        TOTAL SCHOLARSHIP COMMITMENTS
                        SUPPORT OUR EMPLOYEES’ CHILDREN
                        AND THEIR EDUCATIONAL GOALS
                      </div>
                    </div>}
                    hover={
                      <div className='lookingAhead social-flip' style={{ fontSize: width < 501 ? '3vw' : '1rem' }}>
                        We introduced the Waste Connections scholarship program in 2020 as a way to help our employees’ children achieve their vocational, technical and university education goals. The scholarship program awards renewable scholarships to children of Waste Connections’ employees based on academic record, demonstrated leadership, participation in school activities, work experience, career goals and family circumstances.
                        <br />
                        <br />
                        <Link to="/pdf-viewer#top?section=scholarship" alt="Nagivate to the pdf proxy viewer page">
                          LEARN MORE »
                        </Link>
                      </div>}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Container className='diversityAndInclusion' fluid>
        <Row className='content gx-0'>
          <Col xs={12} md={3} className='headerCol'>
            <h2>
              DIVERSITY<br />
              & INCLUSION
            </h2>
            <div className="subHeader">
              We are committed to building and developing diverse teams that function in an environment of mutual respect.
            </div>
          </Col>
          <Col xs={12} md={9} className='items'>
            <Row className='gx-0'>
              <Col xs={4}>
                <div className='pair'>
                  <FadeInOut basic={
                    <img src={womenInTruckingLogo} aria-label="Women in Trucking" height="12px" width="auto" />
                  }
                    hover={
                      <div style={{ fontSize: width < 768 ? '1.75vw' : '1rem' }}>
                        Waste Connections is a supporting member of Women in Trucking.
                      </div>
                    }
                  />
                  <a className='womenInTrucking' href="https://www.womenintrucking.org/" aria-label='Women in Trucking Website'>
                  </a>
                </div>
              </Col>
              <Col xs={4}>
                <div className='pair'>
                  <a className='womenInCleanTech' href="https://www.womenincleantechsustainability.org/" aria-label='Women in Clean Tech and Sustainability Website'>
                  </a>
                  <FadeInOut basic={
                    <img src={womenInCleanTechLogo} aria-label="Women in Clean Tech" />
                  }
                    hover={
                      <div style={{ fontSize: width < 768 ? '1.75vw' : '1rem' }}>
                        Waste Connections is a supporting member of Women in Cleantech & Sustainability.
                      </div>
                    }
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div className='pair'>
                  <FadeInOut basic={
                    <img src={ceoActionLogo} aria-label="CEO Action for Diversity and Inclusion" />
                  }
                    hover={
                      <div style={{ fontSize: width < 768 ? '1.75vw' : '1rem' }}>
                        Waste Connections is a signatory to the CEO Action for Diversity & Inclusion, the largest CEO-driven business commitment to advance diversity and inclusion within the workplace.
                      </div>
                    }
                  />
                  {/* <div className='ceoAction' /> */}
                  <a className='ceoAction' href="https://www.ceoaction.com/" aria-label='CEO Action for Diversity and Inclusion website'>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className='givingBack'>
        <div className='content'>
          <img src={givingBack} alt="" />
          <h3>GIVING BACK AND MAKING AN IMPACT</h3>
          <br />
          <div className='flex flexRow justEven alignCenter fullWidth'>
            <hr />
            <h3>WE DONATED</h3>
            <hr />
          </div>
          <br />
          <Container>
            <Row>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={85}
                  text="K"
                  format="dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  CUMULATIVE BIKES DONATED TO CHILDREN FROM OUR ANNUAL BIKE-BUILD DRIVE
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={10}
                  preText="~$"
                  text="M"
                  format="dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  CUMULATIVE FUNDS RAISED FROM WASTE CONNECTIONS GOLF CLASSIC FOR KIDS
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={1.9}
                  preText="$"
                  text="M"
                  format="(.ddd).dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  RAISED FROM WASTE CONNECTIONS GOLF CLASSIC FOR KIDS IN 2023
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={175}
                  preText=""
                  text=""
                  format="dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  SCHOLARSHIP RECIPIENTS WITH $1.75M IN TOTAL FINANCIAL COMMITMENTS FROM WASTE CONNECTIONS
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={1}
                  preText="$"
                  text="M+"
                  format="dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  ANNUAL CONTRIBUTIONS TO FUND ENVIRONMENTAL RESEARCH, INCLUDING TO THE ENVIRONMENTAL RESEARCH EDUCATION FOUNDATION
                </div>
              </Col>
              <Col xs={12} md={6} lg={4} className='flex flexCol alignCenter'>
                <Odom
                  number={280}
                  preText=""
                  text=""
                  format="dd"
                  style={{
                    height: 72,
                    marginBottom: 8,
                    color: '#009DDC',
                  }}
                  odomStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    color: '#009DDC',
                  }}
                  textStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                  preTextStyle={{
                    fontSize: 64,
                    fontWeight: 'bold',
                    paddingTop: 24,
                    color: '#009DDC',
                  }}
                />
                <div className='detail'>
                  EMPLOYEES HELPED BY EMPLOYEE RELIEF FUND SINCE INCEPTION
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="givingBackBG" />
    </PageWrapper>
  )
}

export default SocialPage;
