import React, { useState, useEffect } from 'react';
import { Row, Container } from 'react-bootstrap';

// import Arrow from '../components/Arrow';
import PageWrapper from '../../components/PageWrapper';
import Button from './components/Button';
import MobileViewer from './components/MobileViewer';
// import proxyPdfSrc from '../../assets/WasteConnections_2022_ESG.pdf';
import proxyPdfSrc from '../../assets/WasteConnections_2023_Sustainability Report.pdf';

//WC_2020_Sustainability_Report_Proxy

const PdfViewer = (props) => {
  const { width, height, isMobile } = props;
  const [page, setPage] = useState(isMobile ? 1 : 0);
  const [initPage, setInitPage] = useState(null);
  // const [loadViewer, setLoadViewer] = useState(false);

  const setPages = (section) => {
    let newPage = 1;
    switch (section) {
      case 'ceo-letter':
        newPage = 4;
        break;
      case 'our-sustainability-targets':
        newPage = 9;
        break;
      case 'social-engagement':
        newPage = 33;
        break;
      case 'esg-highlights':
        newPage = 62;
        break;
      case 'environmental-stewardship':
        newPage = 12;
        break;
      case 'governance-and-ethics':
        newPage = 53;
        break;
      case 'recycling':
        newPage = 18;
        break;
      case 'resource-recovery':
        newPage = 18;
        break;
      case 'leachate-management':
        newPage = 23;
        break;
      case 'zero-waste':
        newPage = 28;
        break;
      case 'fleet-emissions':
        newPage = 27;
        break;
      case 'environmental-management-system':
        newPage = 31;
        break;
      case 'servant-leadership':
        newPage = 35;
        break;
      case 'employee-engagement':
        newPage = 33;
        break;
      case 'employee-training':
        newPage = 41;
        break;
      case 'scholarship':
        newPage = 48;
        break;
      case 'cyber-policy':
        newPage = 60;
        break;
      case 'health-and-safety-policy':
        newPage = 43;
        break;
      case 'renewable-gas-recovery':
        newPage = 21;
        break;
      default:
        newPage = 1
    }
    setPage(newPage);
    setInitPage(newPage);
  }

  useEffect(() => {

    if (window && window.location && window.location.href) {
      const href = window.location.href;
      if (href) {
        const sections = href.split('?section=');
        if (sections && sections.length === 2) {
          setPages(sections[1]);
        } else {
          setPage(1);
        }
      }
    }
    document.title = "Waste Connections Interactive PDF Reader";
    window.scrollTo(0, 0);
  }, [isMobile]);

  const refreshPage = (pageNumber) => {
    if (pageNumber !== page) {
      // setLoadViewer(false);
      setPage(pageNumber);
      // setInitPage(pageNumber);
    }
  }

  useEffect(() => {
    // setLoadViewer(true);
    if (!isMobile) {
      setTimeout(() => {
        const iframeEl = document.getElementsByClassName('pdfIframeContainer');
        if (iframeEl && iframeEl[0] && iframeEl[0].style) {
          iframeEl[0].style.height = (height - 61) + 'px';
        }
      }, 100)
    }

  }, [page, height, width, isMobile]);


  return (
    <PageWrapper topId="top" width={width}>
      <div className="pdfViewer">
        <h1 id="content-start" tabIndex="-1" style={{ textAlign: 'center', color: '#009DDC', marginTop: '4rem' }}>
          2023 SUSTAINABILITY REPORT
        </h1>
        <Container className="content" fluid>

          {/* <Row className="marginTop30">
            <Col>
              <a download href={proxyPdfSrc} aria-label="Download the 2021 Proxy Statement PDF"> */}
          {/* <Arrow text="DOWNLOAD THE 2021 PROXY STATEMENT" /> */}
          {/* </a>
            </Col>
          </Row> */}
          <Row className="marginBottom60 marginTop30">
            <Button title="CEO LETTER" onClick={refreshPage} targetPage={4} pickedTargetPage={page} />
            <Button title="OUR SUSTAINABILITY TARGETS" onClick={refreshPage} targetPage={9} pickedTargetPage={page} />
            <Button title="ESG FRAMEWORKS" onClick={refreshPage} targetPage={62} pickedTargetPage={page} />
            <Button title="ENVIRONMENTAL STEWARDSHIP" onClick={refreshPage} targetPage={12} pickedTargetPage={page} />
            <Button title="SOCIAL ENGAGEMENT" onClick={refreshPage} targetPage={33} pickedTargetPage={page} />
            <Button title="GOVERNANCE & ETHICS" onClick={refreshPage} targetPage={53} pickedTargetPage={page} />
          </Row>
          {
            isMobile ?
              <MobileViewer
                focusedPageIndex={page}
                setFocusedPageIndex={(pageNumber) => {
                  setPage(pageNumber)
                }}
              />
              :
              // loadViewer && 
              <div className="pdfIframeContainer">
                <IFrameWrapper page={page} basePage={1} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={4} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={9} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={62} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={12} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={33} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={53} proxyPdfSrc={proxyPdfSrc} />
                {/* <IFrameWrapper page={page} basePage={17} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={16} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={22} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={26} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={25} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={15} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={35} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={32} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={39} proxyPdfSrc={proxyPdfSrc} />
                <IFrameWrapper page={page} basePage={45} proxyPdfSrc={proxyPdfSrc} /> */}
                {initPage && <IFrameWrapper page={page} basePage={initPage} proxyPdfSrc={proxyPdfSrc} />}
              </div>
          }
          <div className="height200" />
        </Container>
      </div>
    </PageWrapper>
  )


}

export default PdfViewer;

const IFrameWrapper = ({ page, basePage, proxyPdfSrc }) => {
  const [focusable, setFocusable] = useState(true);
  const [lastFocusedPage, setLastFocusedPage] = useState(0);

  useEffect(() => {
    if (!focusable) setFocusable(true);
  }, [focusable]);

  useEffect(() => {
    if (lastFocusedPage === basePage && page !== basePage) {
      setFocusable(false);
    }
    setLastFocusedPage(page);
  }, [page, basePage, lastFocusedPage]);

  if (focusable) return (
    <iframe
      title="Waste Connections Proxy Reader"
      src={`${proxyPdfSrc}#page=${basePage}`}
      className={page === basePage ? "pdfIframe showViewer" : "pdfIframe hideViewer"}
    />
  )

  return null;
}