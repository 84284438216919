import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
//  import proxyPdfSrc from '../../../assets/WasteConnections_2022_ESG.pdf';
import proxyPdfSrc from '../../../assets/WasteConnections_2023_Sustainability Report.pdf';
 //WC_2020_Sustainability_Report_Proxy 
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MobileViewer = (props) => {
  const { focusedPageIndex, setFocusedPageIndex } = props;
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    const docCol = document.getElementById('docCol');
    docCol.scrollIntoView(false);
  }, [focusedPageIndex])

  return (
    <Row id="docRow" className="mobileViewerContainer">
      <Col
        xs={1}
        className="viewProxyNavCol"
        onClick={pdf && focusedPageIndex > 0 ? () => {
          setFocusedPageIndex(focusedPageIndex - 1) }: null}
      >
        {pdf && focusedPageIndex > 0 && '‹'}
      </Col>
      <Col
        xs={10}
        id="docCol"
        className="viewProxyMobileCol"
      >
        <Document
          file={proxyPdfSrc}
          onLoadSuccess={(pdf) => setPdf(pdf)}
        >
          <Page pageNumber={focusedPageIndex} scale={.5} />
        </Document>
      </Col>
      <Col
        xs={1}
        className="viewProxyNavCol"
        onClick={pdf && focusedPageIndex < pdf._pdfInfo.numPages ? () => setFocusedPageIndex(focusedPageIndex + 1) : null}
      >
        {pdf && focusedPageIndex < pdf._pdfInfo.numPages && '›'}
      </Col>
    </Row>
  )
}

export default MobileViewer;